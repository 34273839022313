import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, Input, Label,
} from 'reactstrap';

const EditTimeDetails = ({
  locationDetails, handleFormChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="my-2">
        <Col lg={6} md={12} sm={12}>
          <h4>
            {`${t('genericTerms.time')} ${t(
              'genericTerms.details',
            )}`}
          </h4>
        </Col>
      </Row>
      <Row>

        <Col lg={3} md={4} sm={6}>
          <Label
            htmlFor="orderPrepBufferTime"
            className="required text-muted fs-12px"
          >
            {t('locationConfig.orderPrepTime')}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            type="number"
            className="form-control form-control1"
            placeholder="order buffer time "
            id="orderPrepBufferTime "
            defaultValue={locationDetails?.orderPrepBufferTime}
            name="orderPrepBufferTime"

          />
        </Col>
      </Row>
    </>
  );
};

export default EditTimeDetails;
