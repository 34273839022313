import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import kuberImage from '../static/img/kuberpos.png';
import loaderIcon from '../static/img/cook_loader.gif';
import useAsyncEffect from '../hooks/useAsyncEffect';
import UserServices from './modules/services/UserServices';
import LocalStorageHandler from './modules/auth/LocalStorageHandler';
import Toast from './common/Toast';

const styles = {
  main: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

const Splash = ({ onReady }) => {
  const [toastData, setToastData] = useState(null);
  useAsyncEffect(async () => {
    // localStorage.removeItem('productToken');
    LocalStorageHandler.clearCache(async () => {
      const deviceDetails = getDeviceDetails();
      const toast = { status: 400, message: '' };
      if (deviceDetails) {
        try {
          const response = await UserServices.loginByDeviceId(deviceDetails);
          const result = await response.json();
          toast.status = result.status;
          if (result.status === 200) {
            LocalStorageHandler.setProductToken(result.data[0]?.productToken);
            LocalStorageHandler.setAssetId(result.data[0]?.assetId);
          } else if (result.status === 422) {
            setToastData(result);
          } else {
            toast.message = result.message || 'Please try again';
            setToastData(toast);
            LocalStorageHandler.logout();
          }
          onReady();
        } catch (err) {
          toast.message = err.message || 'Please try again';
          setToastData(toast);
        }
      } else {
        onReady();
      }
    });
  }, []);

  function getDeviceDetails() {
    const str = window.location.pathname.split('/login/');
    const token = str ? str[1] : null;
    let deviceDetails = LocalStorageHandler.getDeviceDetails();
    if (window.location.pathname?.includes('/login') && token) {
      try {
        deviceDetails = jwt_decode(token);
      } catch { }
      LocalStorageHandler.setDeviceDetails(deviceDetails);
    }
    return deviceDetails;
  }

  return (
    <div style={styles.main}>
      <Toast toastData={toastData} />
      <div>
        <img className="img" src={kuberImage} alt="kuber logo" style={{ height: '50%', width: '50%' }} />
        <div>
          <div><img className="img" src={loaderIcon} alt="kuber logo" style={{ height: '45%', width: '40%' }} /></div>
          <h3 style={{ marginTop: '-45px' }}>Loading...</h3>
        </div>
      </div>
    </div>
  );
};

export default Splash;
