/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, Label, Input, Modal, ModalHeader, ModalBody, Form, ModalFooter, Button,
} from 'reactstrap';
import Toast from '../../common/Toast';

const AddVoidReason = ({ voidModal, toggleForm, createReason }) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const [toastData, setToastData] = useState(null);

  const setNotification = (result) => {
    setToastData(result);
  };
  const handleVoidReasonFormChange = (event) => {
    setReason(event.target.value);
  };

  const submit = (el) => {
    el.preventDefault();
    const result = {
      status: 400,
      message: '',
    };
    if (reason === '') {
      result.message = t('errorMessages.voidReasonMandatory');
      setNotification(result);
      return;
    }

    const data = {
      voidId: '',
      voidReason: reason,
      isFlag: 1,
    };
    createReason(data);
  };

  const cancel = () => {
    setReason('');
    toggleForm();
  };
  return (
    <div>

      <Toast toastData={toastData} />
      <Modal isOpen={voidModal} toggle={() => toggleForm()} centered>
        <ModalHeader
          toggle={toggleForm}
          className="modal-title"
          id="exampleModalLabel"
        >
          {`${t('genericTerms.create')} ${t('locationConfig.voidReason')}`}

        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Label className="required text-muted fs-12px">{t('locationConfig.voidReason')}</Label>
                <Input
                  name="voidReason"
                  onChange={(el) => handleVoidReasonFormChange(el)}
                  type="text"
                />
              </Col>

            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-black-color btn btn-black-color1" onClick={() => cancel()}>
            {t('genericTerms.cancel')}
          </Button>
          <Button className="btn-primary-color btn btn-primary-color1" onClick={(el) => submit(el)}>
            {t('genericTerms.save')}
            {' '}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddVoidReason;
