import React, { useContext, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Button } from 'reactstrap';
import { appContext } from '../../common/appUseContext';

const Loader = ({ onCancel }) => {
  const MIN_TIME_OUT = 60000;
  const MAX_TIME_OUT = 180000;
  const { isPayment, updateIsPayment } = useContext(appContext);

  const [showCancel, setShowCancel] = useState(false);
  const timerRef = React.useRef();
  React.useEffect(
    () => {
      timerRef.current = setTimeout(() => {
        setShowCancel(true);
      },
      isPayment === true ? MAX_TIME_OUT : MIN_TIME_OUT);
      return () => {
        clearTimeout(timerRef.current);
      };
    },
    [],
  );

  function handleCancel() {
    clearTimeout(timerRef.current);
    if (typeof onCancel === 'function') {
      updateIsPayment(false);
      onCancel();
    }
  }
  return (
    <>
      <div className="loading">
        <CircularProgress
          size={50}
          color="inherit"
        />
        {
          showCancel && (
            <div style={{
              position: 'absolute',
              left: '-23px',
              marginTop: '25px',
            }}
            >
              <Button size="sm" onClick={handleCancel}>Cancel</Button>
            </div>
          )
        }
      </div>
    </>
  );
};

export default Loader;
