/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col,
  Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label, Input,
} from 'reactstrap';
import ServerNotResponding from '../../common/server-not-responding/ServerNotResponding';
import Toast from '../../common/Toast';

const AddJobRoles = ({
  index, modalShow, submitJobDetails, cancelJobDetails,
  jobRoleRatesToggleForm, jobRoleList,
}) => {
  const { t } = useTranslation();
  const [jobRoleDetails, setJobRoleDetails] = useState({ empJobRoleName: '' });
  const [errorText, setErrorText] = useState('');
  const [toastData, setToastData] = useState(null);
  const hideAlert = () => setErrorText('');
  const setNotification = (result) => setToastData(result);

  const handleFormChange = (event) => {
    setJobRoleDetails({ ...jobRoleDetails, [event.target.name]: event.target.value });
  };
  const validateAlphaNumNoDigits = (value) => {
    const alphaNumericRegex = /^(?=.*)(?=.*[a-zA-Z]).{2,}$/;
    return alphaNumericRegex.test(value);
  };
  const validateDuplicateRoleName = (value) => {
    const ind = jobRoleList.findIndex((item) => item.empJobRoleName.toLowerCase() === value.toLocaleLowerCase());
    return ind;
  };
  const submit = () => {
    const statusMsg = { message: '', status: 400 };
    if (jobRoleDetails.empJobRoleName === '') {
      statusMsg.message = t('errorMessages.jobRoleMandatory');
      setNotification(statusMsg);
    } else if (!validateAlphaNumNoDigits(jobRoleDetails.empJobRoleName)) {
      statusMsg.message = t('errorMessages.jobRoleInvalid');
      setNotification(statusMsg);
    } else if (jobRoleList.length > 0 && validateDuplicateRoleName(jobRoleDetails.empJobRoleName) > -1) {
      statusMsg.message = t('errorMessages.jobRoleAlreadyExists');
      setNotification(statusMsg);
    } else {
      submitJobDetails(jobRoleDetails);
      setJobRoleDetails({ ...jobRoleDetails, empJobRoleName: '' });
    }
  };
  const onClickCancel = () => {
    setJobRoleDetails({ ...jobRoleDetails, empJobRoleName: '' });
    cancelJobDetails();
  };

  return (
    <div>
      <Toast
        toastData={toastData}
      />
      {errorText !== '' && <ServerNotResponding errorText={errorText} hideAlert={hideAlert} />}

      <Modal backdrop={false} keyboard isOpen={modalShow} toggle={() => jobRoleRatesToggleForm()} centered>
        <ModalHeader
          // toggle={() => hideToggleForm()}
          className="modal-title"
          id="exampleModalLabel"
        >
          {index === -1 ? t('genericTerms.create') : t('genericTerms.edit')}
          {' '}
          {`${t('locationConfig.jobRole')} ${t('genericTerms.and')} ${t('locationConfig.jobRates')}`}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FormGroup>
                  <Label className="required text-muted fs-12px">{t('locationConfig.jobRole')}</Label>
                  <Input id="empJobRoleName" name="empJobRoleName" onChange={(event) => handleFormChange(event)} defaultValue={jobRoleDetails.empJobRoleName} />
                </FormGroup>
              </Col>

            </Row>

          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-black-color btn btn-black-color1" onClick={() => onClickCancel()}>
            {t('genericTerms.cancel')}
          </Button>
          <Button onClick={() => submit()} className="btn-primary-color btn btn-primary-color1">
            {index === -1 ? t('genericTerms.create') : t('genericTerms.save')}
            {' '}
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

export default AddJobRoles;
