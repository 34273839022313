import React from 'react';

const toggle = (param) => (
  <div className="md-switch md">
    <input
      type="checkbox"
      id={param.id}
      name={param.name}
      title={param.title}
      checked={param.checked}
      onChange={param.handleValue}
      className="md-toggle md-toggle-round"
    />
    <label htmlFor={param.name}><span /></label>
  </div>
);
export default toggle;
