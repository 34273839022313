import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, Label, Input,
} from 'reactstrap';
import TimePicker from 'react-datetime';

const EditTaxDetails = ({ locationDetails, handleFormChange, handleTaxFreeDateChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="my-2">
        <Col
          lg={6}
          md={12}
          sm={12}
        >
          <h4>
            {`${t('genericTerms.tax')} ${t(
              'genericTerms.details',
            )}`}
          </h4>
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>

          <Label className="required text-muted fs-12px">
            {`${t('genericTerms.city')} ${t(
              'genericTerms.tax',
            )}`}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            type="number"
            className="form-control form-control1"
            placeholder="City Tax"
            id="cityTax"
            defaultValue={locationDetails?.cityTax}
            name="cityTax"
          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="stateTax" className="required text-muted fs-12px">
            {`${t('genericTerms.state')} ${t(
              'genericTerms.tax',
            )}`}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            type="number"
            className="form-control form-control1"
            placeholder="State Tax"
            id="stateTax"
            defaultValue={locationDetails?.stateTax}
            name="stateTax"

          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="gratuityPercentage" className="text-muted fs-12px">
            {`${t('genericTerms.gratuity')} ${t(
              'genericTerms.percentage',
            )}`}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
                        // ref={(el) => (this.gratuityPercentage = el)}
            type="number"
            className="form-control form-control1"
            placeholder="Gratuity Percentage"
            id="gratuityPercentage"
            defaultValue={locationDetails?.gratuityPercentage}
            name="gratuityPercentage"

          />
        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="noOfGuest" className="required text-muted fs-12px">
            {`${t('genericTerms.gratuity')} ${t(
              'locationConfig.noOfGuest',
            )}`}
            {' '}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            type="number"
            className="form-control form-control1"
            placeholder="No of Guest"
            id="noOfGuest"
            defaultValue={locationDetails?.noOfGuest}
            name="noOfGuest"

          />

        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>
          <Label htmlFor="openTime" className="required text-muted fs-12px">
            {`${t('genericTerms.tax')} ${t(
              'genericTerms.free',
            )}${t('genericTerms.date')}`}
          </Label>
          <TimePicker
            className="form-control1"
            value={locationDetails.taxFreeDate}
            onChange={(e) => handleTaxFreeDateChange(e)}
          />
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default EditTaxDetails;
