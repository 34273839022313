/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  Row, Col, Label, Input,
} from 'reactstrap';
import EditLocationDetails from './EditLocationDetails';

const EditContactDetails = ({
  locationDetails, handleFormChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="my-2">
        <Col lg={6} md={12} sm={12}>
          <h4>
            {`${t('genericTerms.contact')} ${t('genericTerms.details')}`}
          </h4>
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="address1" className="required text-muted fs-12px">
            {t('genericTerms.addressLine1')}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            type="text"
            className="form-control form-control1"
            // ref={(el) => (this.addressLine1 = el)}
            placeholder="Address Line 1"
            id="address1"
            defaultValue={locationDetails?.addressLine1}
            name="addressLine1"
          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label className="text-muted fs-12px" htmlFor="address2">
            {t('genericTerms.addressLine2')}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            type="text"
            className="form-control form-control1"
            //   ref={(el) => (this.addressLine2 = el)}
            placeholder="Address Line 2"
            id="address2"
            defaultValue={locationDetails?.addressLine2}
            name="addressLine2"

          />
        </Col>

        <Col lg={3} md={4} sm={6}>
          <Label htmlFor="address3" className="text-muted fs-12px">
            {t('genericTerms.addressLine3')}
          </Label>
          <Input
            type="text"
            className="form-control form-control1"
            //   ref={(el) => (this.addressLine3 = el)}
            placeholder="Address Line 3"
            id="address3"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.addressLine3}
            name="addressLine3"

          />
        </Col>
        <Col lg={3} md={4} sm={6}>
          <Label htmlFor="address4" className="text-muted fs-12px">
            {t('genericTerms.addressLine4')}
          </Label>
          <Input
            type="text"
            onChange={(event) => handleFormChange(event)}
            className="form-control form-control1"
            //   ref={(el) => (this.addressLine4 = el)}
            placeholder="Address Line 4"
            id="address4"
            defaultValue={locationDetails?.addressLine4}
            name="addressLine4"

          />
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="city" className="required text-muted fs-12px">
            {t('genericTerms.city')}
          </Label>
          <Input
            type="text"
            className="form-control form-control"
            //   ref={(el) => (this.city = el)}
            placeholder="City"
            id="city"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.city}
            name="city"

          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="county" className="required text-muted fs-12px">
            {t('genericTerms.county')}
          </Label>
          <Input
            type="text"
            className="form-control form-control1"
            //   ref={(el) => (this.county = el)}
            placeholder="County"
            id="county"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.county}
            name="county"

          />
        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="state" className="required text-muted fs-12px">
            {t('genericTerms.state')}
          </Label>
          <Input
            type="text"
            className="form-control form-control1"
            onChange={(event) => handleFormChange(event)}
            //   ref={(el) => (this.CityState = el)}
            placeholder="State"
            id="state"
            name="CityState"
            defaultValue={locationDetails?.CityState}
          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="country" className="required text-muted fs-12px">
            {t('genericTerms.country')}
          </Label>
          <Input
            type="text"
            className="form-control form-control1"
            //   ref={(el) => (this.country = el)}
            placeholder="Country"
            id="country"
            name="country"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.country}
          />

        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="zipcode" className="required text-muted fs-12px">
            {t('genericTerms.zipCode')}
          </Label>
          <Input
            type="number"
            className="form-control form-control1"
            //   ref={(el) => (this.zipCode = el)}
            placeholder="Zip Code"
            id="zipcode"
            name="zipCode"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.zipCode}
          />

        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <EditLocationDetails
            locationDetails={locationDetails}
            handleFormChange={handleFormChange}

          />
        </Col>
      </Row>

    </>
  );
};

export default EditContactDetails;
