/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, ButtonGroup,
  CustomInput, Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Label,
} from 'reactstrap';
import { appContext } from '../../common/appUseContext';
import ServerNotResponding from '../../common/server-not-responding/ServerNotResponding';
import Toast from '../../common/Toast';
import { setStatusMessage } from '../admin/menu-category/business/useBusiness';
import ServiceHandler from '../admin/menu-category/service-handler/ServiceHandler';

const repeatType = ['Daily', 'Weekly'];
const AddUpdateTimeTable = ({
  props, index, timeTableDetails, modalShow,
  timeTableToggleForm, updatedTimeTableDetails,
}) => {
  const [toastData, setToastData] = useState(null);
  const [weekDays, setWeekDays] = useState([]);
  const [isWeekly, setIsWeekly] = useState(false);
  // const [repeatType,setRepeatType] = useState(['Daily', 'Weekly']);
  const [type, setType] = useState('');
  const { t } = useTranslation();
  const { updateEditingData } = useContext(appContext);
  const [errorText, setErrorText] = useState('');
  const hideAlert = () => setErrorText('');
  useEffect(() => {
    getWeekDays();
  }, [timeTableDetails?.timeTableRepeatType]);

  const onSelectRepeatType = (event) => {
    if (event.target.value === 'select') {
      setIsWeekly(false);
      const result = setStatusMessage(400, t('errorMessages.repeatTypeMandatory'));
      setNotification(result);
      return;
    }
    if (event.target.value === 'Weekly') {
      setIsWeekly(true);
    } else {
      setIsWeekly(false);
    }

    setLocalStorage();
    setType(event.target.value);
  };
  const setLocalStorage = () => {
    updateEditingData(true);
  };
  const onSelectWeeksDays = (el, i) => {
    const days = [...weekDays];
    days[i].selected = !days[i].selected;
    setWeekDays(days);
    setLocalStorage();
  };

  const setNotification = (result) => {
    setToastData(result);
  };
  const getWeekDays = async () => {
    try {
      const result = await ServiceHandler.getWeekDays(props);
      if (result.status === 200) {
        if (result.data?.weekDays?.length > 0) {
          if (index > -1) {
            const position = result.data.weekDays.map((item) => item.day
              === timeTableDetails?.timeTableRepeatType);
            if (position > -1) {
              result.data.weekDays[position].selected = true;
            }
          }
        }
        let days = result.data.weekDays;
        if (timeTableDetails?.timeTableRepeatType === 'Weekly') setIsWeekly(true);
        setType(timeTableDetails?.timeTableRepeatType);
        if (timeTableDetails) {
          if (timeTableDetails?.timeTableRepeatType === 'Weekly') {
            if (timeTableDetails?.timeTableRecurring?.length > 0) {
              // eslint-disable-next-line array-callback-return
              days = selectedDays(result.data.weekDays);
              // timeTableDetails.timeTableRecurring.map((dayId) => {
              //   if (dayId === 0) result.data.weekDays[0].selected = true;
              //   if (dayId === 1) result.data.weekDays[1].selected = true;
              //   if (dayId === 2) result.data.weekDays[2].selected = true;
              //   if (dayId === 3) result.data.weekDays[3].selected = true;
              //   if (dayId === 4) result.data.weekDays[4].selected = true;
              //   if (dayId === 5) result.data.weekDays[5].selected = true;
              //   if (dayId === 6) result.data.weekDays[6].selected = true;
              // });
            }
          }
        }
        setWeekDays(days);
      } else {
        setNotification(result);
      }
    } catch (err) {
      setErrorText(t('genericTerms.serverNotResponding'));
    }
  };
  const submit = () => {
    if (type === 'Weekly') {
      let count = 0;
      weekDays.map((item) => (item.selected === true ? count += 1 : count));
      if (count === 0) {
        const result = setStatusMessage(400, t('customers.selectDays'));
        setNotification(result);
        return;
      }
    }
    const ids = [];
    weekDays.map((item) => (item.selected === true ? ids.push(item.id) : []));
    const data = {
      timeTableId: props.timeTableId,
      timeTableRepeatType: type,
      timeTableRecurring: type === 'Daily' ? [] : ids,
    };
    updatedTimeTableDetails(data);
    resetValue();
  };
  const hideToggleForm = () => {
    resetValue();
    updateEditingData(false);
    timeTableToggleForm(index);
  };
  const resetValue = () => {
    if (timeTableDetails) {
      if (timeTableDetails?.timeTableRepeatType === 'Weekly') {
        if (timeTableDetails?.timeTableRecurring?.length > 0) {
          const days = selectedDays(weekDays);
          setWeekDays(days);
          setType('Weekly');
        }
      } else {
        setType('Daily');
      }
    } else {
      setType('Daily');
      const days = weekDays;
      days.map((item) => item.selected = false);
      setWeekDays(days);
    }
    setIsWeekly(false);
  };

  const selectedDays = (list) => {
    const days = list;
    days.map((item) => item.selected = false);
    // eslint-disable-next-line array-callback-return
    timeTableDetails.timeTableRecurring.map((dayId) => {
      if (dayId === 0) days[0].selected = true;
      if (dayId === 1) days[1].selected = true;
      if (dayId === 2)days[2].selected = true;
      if (dayId === 3)days[3].selected = true;
      if (dayId === 4) days[4].selected = true;
      if (dayId === 5) days[5].selected = true;
      if (dayId === 6) days[6].selected = true;
    });
    return days;
  };

  return (
    <div>
      <Toast
        toastData={toastData}
      />
      {errorText !== '' && <ServerNotResponding errorText={errorText} hideAlert={hideAlert} />}

      <Modal backdrop={false} keyboard isOpen={modalShow} toggle={() => hideToggleForm()} centered>
        <ModalHeader
          // toggle={() => hideToggleForm()}
          className="modal-title"
          id="exampleModalLabel"
        >
          {index === -1 ? t('genericTerms.create') : t('genericTerms.edit')}
          {' '}
          {t('category.categoryTimeTable')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <FormGroup>
                  <Label className="required text-muted fs-12px" for="exampleCustomSelect">{t('category.timeTableRepeatType')}</Label>
                  <CustomInput defaultValue={timeTableDetails?.timeTableRepeatType} onChange={(element) => onSelectRepeatType(element)} type="select" id="exampleCustomSelect" name="customSelect">
                    <option value="select">Select</option>
                    {repeatType.map((item, i) => <option key={i} value={item}>{item}</option>)}

                  </CustomInput>
                </FormGroup>
              </Col>

            </Row>

            {(isWeekly || type === 'Weekly')
              && (
                <Row>
                  <Col lg={12} md={12} sm={12}>
                    <Label className="text-muted required fs-12px">{t('customers.selectDays')}</Label>
                    <ButtonGroup
                      className="btn-group-toggle w-100 "
                      data-toggle="buttons"
                    >
                      {weekDays.map((item, i) => (
                        <Button
                          style={{ minWidth: '10px' }}
                          size="sm"
                          key={i}
                          color={item.selected ? 'info' : 'btn-secondary'}
                          onClick={(el) => onSelectWeeksDays(el, i)}
                          className={item.selected ? 'btn-round btn btn btn-info' : 'btn-round btn btn btn-secondary'}
                        >
                          <input name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            {item.day}
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                          </span>
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Col>
                </Row>
              )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-black-color btn btn-black-color1" onClick={() => hideToggleForm()}>
            {t('genericTerms.cancel')}
          </Button>
          <Button onClick={() => submit()} className="btn-primary-color btn btn-primary-color1">
            {index === -1 ? t('genericTerms.create') : t('genericTerms.save')}
            {' '}
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

export default AddUpdateTimeTable;
