/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { toFloat } from '../../../../common/utils/number-utils';
import { getTimeSlot, getTimeTable } from '../../menu-category/payloads/Payloads';

const format = 'hh:mm A';
export function getOpenCloseTimeDetails(openTimeData, closeTimeData) {
  const splitOpenTime = openTimeData.split(' ');
  const splitColloanOpenTime = splitOpenTime[0].split(':');

  const splitCloseTime = closeTimeData.split(' ');
  const splitColloanCloseTime = splitCloseTime[0].split(':');
  let openTime = openTimeData;
  let closeTime = closeTimeData;
  if (splitOpenTime[1] === 'PM') {
    if (splitColloanOpenTime[0] === '12') {
      openTime = splitOpenTime[0];
    } else if (
      splitColloanOpenTime[0] < 12
      || splitColloanOpenTime[0] < '12'
    ) {
      openTime = `${12 + +splitColloanOpenTime[0]}:${splitColloanOpenTime[1]}`;
    }
  } else {
    openTime = splitOpenTime[0];
  }
  if (splitCloseTime[1] === 'PM') {
    if (splitColloanCloseTime[0] === '12') {
      closeTime = splitCloseTime[0];

      // closeTime = splitCloseTime[0] +  ' '+splitCloseTime[1]
    } else if (
      splitColloanCloseTime[0] < 12
      || splitColloanCloseTime[0] < '12'
    ) {
      closeTime = `${12 + +splitColloanCloseTime[0]}:${splitColloanCloseTime[1]}`;
    }
  } else {
    closeTime = splitCloseTime[0];
  }

  return { openTime, closeTime };
}

export function getUpdateRequest(locationId, locationDetails, voidDataList, timeSlot, timeTable, jobRoleData) {
  const REQ = {
    locationId,
    locationCuisineType: locationDetails.cuisineType,
    locationWeightUnits: locationDetails.weightUnits,
    locationCurrencyType: locationDetails.locationCurrencyType,
    locationBusinessName: locationDetails.businessName,
    locationVersion: locationDetails.locationVersion,
    locationType: locationDetails.locationType,
    locationTaxFreeDate: moment(locationDetails.taxFreeDate, format).format(
      'YYYY/MM/DD',
    ),
    locationAddrLine1: locationDetails.addressLine1,
    locationAddrLine2: locationDetails.addressLine2,
    locationAddrLine3: locationDetails.addressLine3,
    locationAddrLine4: locationDetails.addressLine4,
    locationAddrZipCode: locationDetails.zipCode,
    locationAddrCity: locationDetails.city,
    locationAddrState: locationDetails.CityState,
    locationAddrCounty: locationDetails.county,
    locationAddrCountry: locationDetails.country,
    locationStateTax: +locationDetails.stateTax,
    locationCityTax: +locationDetails.cityTax,
    locationGeoCode: locationDetails.geoCode,
    locationContactNumber: +locationDetails.contactNumber,
    // locationSmsNumber: +locationDetails.smsNumber,
    locationEmail: locationDetails.emailId,
    locationGratuityPercentage: +locationDetails.gratuityPercentage,
    locationGratuityNoOfGuest: +locationDetails.noOfGuest,
    locationIsActive: locationDetails.locationActive,
    maxCrAmount: toFloat(locationDetails.maxCRAmount),
    orderPrepBufferTime: +locationDetails.orderPrepBufferTime,
    rewardsPointsValue: +locationDetails.rewardsPointsValue,
    deliveryCharges: toFloat(locationDetails.deliveryCharges),
    paymentVpLinkValidityTime: +locationDetails.paymentVpLinkValidityTime,
    expDays: locationDetails.expDays,
    rwdMinPoints: locationDetails.rwdMinPoints,
    status: locationDetails.status,
    isConfirmAmount: locationDetails.isConfirmAmount,
    localhostIp: locationDetails.localhostIp,
    localhostPort: locationDetails.localhostPort,
    awsUrl: locationDetails.awsUrl,
    inventoryManagementActiveStatus: locationDetails.inventoryManagementActiveStatus,
    voidReasons: voidDataList,
    timeSlot: getTimeSlot(timeSlot),
    timeTable: getTimeTable(timeTable),
    lat: locationDetails.latitude !== null ? locationDetails.latitude : '',
    long: locationDetails.longitude !== null ? locationDetails.longitude : '',
    jobRoleDetails: getJobRoleDetails(jobRoleData),
    isZeroTaxForOosTogo: locationDetails?.isZeroTaxForOosTogo || false,
  };

  return REQ;
}
export function getJobRoleDetails(list) {
  const newArray = [];
  if (list.length > 0) {
    list.map((item) => {
      newArray.push({ empJobRoleId: item.empJobRoleId || '', empJobRoleName: item.empJobRoleName || '', status: item.status || 0 });
    });
  }
  return newArray;
}
export function updateDelayETAPayload(delayMinutes) {
  const REQ = {
    delayMinutes,
  };
  return REQ;
}
export function timeSlotPayload(type, slotData) {
  let payload = null;
  const slots = {
    timeSlotName: slotData.slotName,
    timeSlotStartTime: slotData.slotStartTime,
    timeSlotEndTime: slotData.slotStopTime,
    timeSlotId: slotData.slotId,
  };
  if (type === 'create') {
    payload = {
      timeSlots: [
        {
          timeSlotName: slotData.slotName,
          timeSlotStartTime: slotData.slotStartTime,
          timeSlotEndTime: slotData.slotStopTime,
        },
      ],
    };
  } else if (type === 'update') {
    payload = {
      timeSlot:
        {
          timeSlotName: slotData.slotName,
          timeSlotStartTime: slotData.slotStartTime,
          timeSlotEndTime: slotData.slotStopTime,
          timeSlotId: slotData.slotId,
        }
      ,

    };
  } else {
    payload = {
      timeSlot:
        {
          timeSlotId: slotData.slotId,
        }
      ,

    };
  }
  return payload;
}
