import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, FormGroup, Label, Input,
} from 'reactstrap';

const EditLocationDetails = ({ locationDetails, handleFormChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="my-2">
        <Col lg={6} md={12} sm={12}>
          <h4>
            {`${t('genericTerms.location')} ${t(
              'genericTerms.details',
            )}`}
          </h4>
        </Col>

      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>
          <FormGroup>
            <Label className="required text-muted fs-12px">{t('oosConfig.latitude')}</Label>
            <Input
              value={locationDetails?.latitude}
              onChange={(element) => handleFormChange(element)}
              name="latitude"
              id="latitude"
              type="number"
            />
          </FormGroup>

        </Col>
        <Col lg={3} md={4} sm={6}>
          <FormGroup>
            <Label className="required text-muted fs-12px">{t('oosConfig.longitude')}</Label>
            <Input
              value={locationDetails?.longitude}
              onChange={(element) => handleFormChange(element)}
              name="longitude"
              id="longitude"
              type="number"
            />
          </FormGroup>

        </Col>

      </Row>

    </div>

  );
};

export default EditLocationDetails;
