/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  Row, Col, Table, Button,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const EditVoidReason = ({ reasonList, deleteReason, toggleForm }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="my-2">
        <Col md={11}>
          <h4>
            {`${t('locationConfig.voidReason')} ${t(
              'genericTerms.details',
            )}`}
          </h4>
        </Col>
        <Col md={1} className="createMenyType createCategoryList">
          <span className="addNewBtnSec">
            <Button
              type="button"
              className="btn  btn-primary-color btn-primary-color1"
              onClick={() => toggleForm()}
            >
              <i className="fa fa-plus" />
              {' '}
              {`${t('genericTerms.new')}`}
            </Button>
          </span>
        </Col>
      </Row>
      {reasonList.length > 0 && (
      <Row>
        <Col lg={8} md={8} sm={12}>
          <Table className="border">
            <thead className="top-border-1">
              <tr style={{ topBorder: '3px' }}>
                <th>S.No</th>
                <th>Reason</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              {reasonList?.map((item, i) => (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>{item.voidReason}</td>
                  <td>
                    {' '}
                    <i
                      onClick={() => deleteReason(i)}
                      className="fas fa-trash text-danger cursor-pointer pt-2 pb-2 pl-3 pr-3"
                    />
                  </td>

                </tr>
              ))}
            </tbody>
          </Table>

        </Col>
      </Row>

      )}
    </>
  );
};

export default EditVoidReason;
