const compose = (f, g) => (x) => f(g(x));

const removeCommas = (val) => (val && typeof val === 'string' ? val.replace(/,/g, '') : val);
const float = (val) => parseFloat(val);
const fixed2 = (val) => removeCommas(val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'decimal' }));
const fixed3 = (val) => removeCommas(val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 3, style: 'decimal' }));
const toFloat = compose(fixed2, float);
const convertToNumber = (val) => Number(val);
const toInt = compose(convertToNumber, removeCommas);
const subtract = (val1, val2) => toInt(val1) - toInt(val2);
const divide = (val1, val2) => toFloat(toInt(val1) / toInt(val2));
const sum = (...values) => fixed2(values.reduce((x, y) => toInt(x) + toInt(y)));
const multiply = (...values) => fixed2(values.reduce((x, y) => toInt(x) * toInt(y)));
const percentCurry = (val1) => (val2) => toFloat((toInt(val1) * toInt(val2)) / 100);
const toNumber = (val) => parseInt(val, 10);
const toNumberFixed2 = compose(fixed2, toNumber);
const isGreaterThan = (val1, val2) => toInt(toFloat(val1)) > toInt(toFloat(val2));
// const sum = (...values, callback=fixed2) => callback(values.reduce((x, y) => toInt(x) + toInt(y)));
const sumFixed3 = (...values) => fixed3(values.reduce((x, y) => toInt(x) + toInt(y)));

const isEqual = (val1, val2) => toInt(toFloat(val1)) === toInt(toFloat(val2));

/**
 * @param value discount value
 * @param total total order amount
 * @returns percentage
 */
const getPercentage = (value, total) => fixed2((convertToNumber(value) / convertToNumber(total)) * 100);

/**
 * @param percentage of the discount derived from the order total amount
 * @param price menu item price in the cart
 * @returns discounted menu price
 */
const applyDiscount = (percentage, price) => ((convertToNumber(price) * convertToNumber(percentage)) / 100);

module.exports = {
  toFloat,
  float,
  fixed2,
  fixed3,
  sumFixed3,
  toNumber,
  toNumberFixed2,
  subtract,
  divide,
  toInt,
  sum,
  percentCurry,
  isGreaterThan,
  isEqual,
  multiply,
  applyDiscount,
  getPercentage,
};
