/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
import React from 'react';
import {
 useBlockLayout, useExpanded, useFilters, usePagination, useSortBy, useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import {
  Col,
  Input, Pagination,
  PaginationItem,
  PaginationLink, Row,
} from 'reactstrap';
import CanShow from '../CanShow';
import './TableSticky.scss';
/**
 *
 * @param {Boolean} showPagination
 * @param {Object} options {pageSize}
 * @param {String} width height of table
 * @param {String} height height of table (mandatory)
 * @param {Array} data data array to render in table
 * @param {Array} columns memorized array of columns (react-table)
 */
const TableSticky = ({
  showPagination = false, options = {},
  height = height, width = '100%', columns, data, className = '', hasFooter = hasFooter,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page: rows, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: showPagination ? options?.pageSize || 20 : data?.length },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useSticky,
  );
  const generateSortingIndicator = (column) => (column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '');

  // Workaround as react-table footerGroups doesn't provide the same internal data than headerGroups
//   const footerGroups = headerGroups.slice().reverse();
  return (
    <div className={`TableSticky ${className}`}>

      {/* ==========TABLE============ */}
      <div {...getTableProps()} className="table sticky" style={{ width }}>
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div
                  {...column.getHeaderProps({ style: { width: column.width } })}
                  className="th"
                >
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                  {/* {column.render('Header')} */}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map((cell) => (
                  <div {...cell.getCellProps({ style: { width: cell.column.width } })} className="td">
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <CanShow show={hasFooter}>
          <div className="footer">
            {footerGroups.map((footerGroup) => (
              <div {...footerGroup.getHeaderGroupProps()} className="tr">
                {footerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps({ style: { width: column.width, textAlign: 'center' } })} className="td">
                    {column.render('Footer')}
                  </div>
              ))}
              </div>
          ))}
          </div>
        </CanShow>

      </div>

      {/* ==========PAGINATION============ */}
      <CanShow show={showPagination}>
        <Row noGutters className="px-3">
          <Col className="d-flex flex-row">
            <div className="mr-3">

              <strong>
                {`Page ${pageIndex + 1} of ${pageOptions.length}`}
              </strong>
            </div>
            <div className="d-flex mr-3">
              Go to page:
              <Input
                type="number"
                bsSize="sm"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '80px' }}
              />
            </div>
            <div className="mr-3">
              <select
                className="form-control   form-control-sm"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pSize) => (
                  <option key={pSize} value={pSize} className="text-muted">
                    Show
                    {' '}
                    {pSize}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col className="float-right">
            <Pagination className="float-right">
              <PaginationItem disabled={!canPreviousPage}>
                <PaginationLink
                  style={{ fontSize: '20px' }}
                  onClick={() => gotoPage(0)}
                  first
                />
              </PaginationItem>
              <PaginationItem disabled={!canPreviousPage}>
                <PaginationLink
                  style={{ fontSize: '20px' }}
                  onClick={() => previousPage()}
                  previous
                />
              </PaginationItem>
              {pageOptions.map((op, index) => (
                <PaginationItem key={index} active={pageIndex === index}>
                  <PaginationLink onClick={() => gotoPage(index)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={!canNextPage}>
                <PaginationLink
                  style={{ fontSize: '20px' }}
                  onClick={() => nextPage()}
                  next
                />
              </PaginationItem>
              <PaginationItem disabled={!canNextPage}>
                <PaginationLink
                  style={{ fontSize: '20px' }}
                  onClick={() => gotoPage(pageCount - 1)}
                  last
                />
              </PaginationItem>
            </Pagination>

          </Col>
        </Row>
      </CanShow>

    </div>
  );
};

export default TableSticky;
