/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row, Col, Label, Input, Modal, ModalHeader, ModalBody, Form, ModalFooter, Button,
} from 'reactstrap';
import TimePicker from 'react-datetime';
import moment from 'moment';
import Toast from '../../common/Toast';
import { setStatusMessage } from '../admin/menu-category/business/useBusiness';
import { appContext } from '../../common/appUseContext';

const format = 'HH:mm';

const AddUpdateTimeSlotCategoryModal = ({
  index, slotDetails, updateSlotDetails, timeSlotToggleForm, modalShow,
}) => {
  const { t } = useTranslation();
  const { updateEditingData } = useContext(appContext);
  const [toastData, setToastData] = useState(null);
  const [validTime, setValidTime] = useState({
    startTime: false,
    stopTime: false,
  });
  const currentTime =  moment(new Date()).format(format)

  const [slotStartTime, setSlotStartTime] = useState(slotDetails ? slotDetails?.slotStartTime : currentTime);
  const [slotStopTime, setSlotStopTime] = useState(slotDetails ? slotDetails?.slotStopTime : currentTime);
  const [slotName, setSlotName] = useState(slotDetails?.slotName);
  const handleSlotNameFormChange = (el) => setSlotName(el.target.value);
  const setNotification = (result) => setToastData(result);
  useEffect(() => {
    if (index !== -1) setInputValues();
  }, [slotDetails]);

  const setInputValues = () => {
    setSlotName(slotDetails?.slotName);
    setSlotStartTime(slotDetails?.slotStartTime);
    setSlotStopTime(slotDetails?.slotStopTime);
  };

  const handleErrors = (errorMessage) => setNotification(setStatusMessage(400, errorMessage));

  const submit = () => {
    if (slotName === undefined || slotName === '') {
      handleErrors(t('errorMessages.slotNameMandatory'));
    } else if (validTime.startTime) {
      handleErrors(t('errorMessages.ValidSlotStartTime'));
    } else if (slotStartTime === undefined || slotStartTime === '') {
      handleErrors(t('errorMessages.slotStartTimeMandatory'));
    } else if (validTime.stopTime) {
      handleErrors(t('errorMessages.ValidSlotStopTime'));
    } else if (slotStopTime === undefined || slotStopTime === '') {
      handleErrors(t('errorMessages.slotStopTimeMandatory'));
    } else {
      const data = {
        slotId: index === -1 ? 0 : slotDetails.slotId,
        slotName,
        slotStartTime,
        slotStopTime,
      };
      updateSlotDetails(data);
    }
  };

  const toggleForm = () => {
    resetValue();
    updateEditingData(false);
    timeSlotToggleForm(index);
  };

  const resetValue = () => {
    setSlotName( '');
    setSlotStartTime(currentTime);
    setSlotStopTime(currentTime);
  };
  const handleStartTimeChange = (event) => {
    if (event === '') {
      setSlotStartTime('');
      setValidTime((oldValue) => ({ ...oldValue, startTime: false }));
    } else if (event._d === undefined) {
      setValidTime((oldValue) => ({ ...oldValue, startTime: true }));
    } else {
      const formattedTime = moment(event._d).format(format);
      setSlotStartTime(formattedTime);
      setValidTime((oldValue) => ({ ...oldValue, startTime: false }));
    }
  };

  const handleStopTimeChange = (event) => {
    if (event === '') {
      setSlotStopTime('');
      setValidTime((oldValue) => ({ ...oldValue, stopTime: false }));
    } else if (event._d === undefined) {
      setValidTime((oldValue) => ({ ...oldValue, stopTime: true }));
    } else {
      const formattedTime = moment(event._d).format(format);
      setSlotStopTime(formattedTime);
      setValidTime((oldValue) => ({ ...oldValue, stopTime: false }));
    }
  };

  return (
    <div>
      <Toast toastData={toastData} />
      <Modal backdrop={false} keyboard isOpen={modalShow} toggle={() => toggleForm()} centered>
        <ModalHeader className="modal-title" id="exampleModalLabel">
          {index === -1 ? t('genericTerms.create') : t('genericTerms.edit')}
          {' '}
          {t('menu.categoryTimeSlot')}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <Label className="required text-muted fs-12px">{t('category.slotName')}</Label>
                <Input
                  defaultValue={index === -1 ? slotName : slotDetails?.slotName}
                  name="slotName"
                  onChange={(el) => handleSlotNameFormChange(el)}
                  type="text"
                />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <Label className="required text-muted fs-12px">{t('category.slotStartTime')}</Label>
                <TimePicker
                  className="form-control1"
                  dateFormat={false}
                  timeFormat="HH:mm"
                  name="slotStartTime"
                  defaultValue={index === -1 ? slotStartTime : slotDetails?.slotStartTime}
                  onChange={(e) => handleStartTimeChange(e)}
                />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <Label className="required text-muted fs-12px">{t('category.slotStopTime')}</Label>
                <TimePicker
                  className="form-control1"
                  dateFormat={false}
                  timeFormat="HH:mm"
                  name="slotStopTime"
                  defaultValue={index === -1 ? slotStopTime : slotDetails?.slotStopTime}
                  onChange={(e) => handleStopTimeChange(e)}
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-black-color btn btn-black-color1" onClick={() => toggleForm()}>
            {t('genericTerms.cancel')}
          </Button>
          <Button className="btn-primary-color btn btn-primary-color1" onClick={() => submit()}>
            {index === -1 ? t('genericTerms.create') : t('genericTerms.save')}
            {' '}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddUpdateTimeSlotCategoryModal;
