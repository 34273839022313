/* eslint-disable no-param-reassign */
/**
 * @author Akhil
 * @since 1.0
 * @type React Component
 * @description Notification Display for all services.
 */

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

toast.configure();

// const Toast = (props) => {
//   const [options, setOptions] = useState({});

//   const handleToast = () => {
//     if (props.statusCode && props.toastData) {
//       if (props.statusCode === 422 && props.toastData && props.toastData.errors) {
//         props.toastData.errors.forEach((element) => toast.info(element.msg || element.message, options));
//       } else if (props.statusCode === 400 || props.statusCode === 401) {
//         if (props.toastData.message !== '') toast.error(props.toastData.message, options);
//       } else if (props.statusCode === 200 || props.statusCode === 201) {
//         if (props.toastData.message !== '') toast.success(props.toastData.message, options);
//       } else if (props.toastData.message !== '') toast.error(props.toastData.message, options);
//     }
//   };

//   useEffect(() => {
//     setOptions({
//       position: 'top-right',
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       className: 'roundedEdgesAb',
//     });
//     handleToast();
//     if (props.toastData !== null) {
//       if (props.toastData.length > 0) {
//         if (props.toastData[0].message !== '') props.toastData[0].message = '';
//       } else if (props.toastData === false) {
//         props.toastData.message = '';
//       } else if (props.toastData !== '') props.toastData.message = '';
//     }
//   }, [props.statusCode, props.toastData]);

//   return (
//     <>
//     </>
//   );
// };
const Toast = (props) => {
  const [options, setOptions] = useState({});

  const handleToast = () => {
    if (props.toastData) {
      if (props.toastData?.status === 422 && props.toastData.errors) {
        props.toastData.errors.forEach((element) => toast.error(element.msg || element.message, options));
      } else if (props.toastData?.status === 400 || props.toastData?.status === 401) {
        if (props.toastData.message !== '') toast.error(props.toastData.message, options);
      } else if (props.toastData?.status === 200 || props.toastData?.status === 201) {
        if (props.toastData.message !== '') toast.success(props.toastData.message, options);
      } else if (props.toastData.message !== '') toast.error(props.toastData.message, options);
    }
  };
  useEffect(() => {
    setOptions({
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: 'roundedEdgesAb',
    });
    handleToast();
    if (props.toastData !== null) {
      if (props.toastData.length > 0) {
        if (props.toastData[0].message !== '') props.toastData[0].message = '';
      } else if (props.toastData === false) {
        props.toastData.message = '';
      } else if (props.toastData !== '') props.toastData.message = '';
    }
  }, [props.toastData]);

  return (
    <>
    </>
  );
};
export default Toast;
