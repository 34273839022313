/**
 * @author Manimalarselvi
 * @since 1.0
 * @type React Component
 * @description Private router components
 */

// TODO  if we need first login we can use this code

// import React from "react";
// import { Redirect, Route } from "react-router-dom";
// import log from "../common/logger";
// import LocalStorageHandler from '../modules/auth/LocalStorageHandler';

// const PrivateRoute = ({ component: Component, path, ...rest }) => {
//     log.info('*** PrivateRoute ***');
//     return (
//         <Route path={path}{...rest} render={props => {
//             return (LocalStorageHandler.getKuberAuthenticated())
//                 ? (LocalStorageHandler.getUserAuthenticated())
//                     ? (<Component  {...props} />)
//                     : (<Redirect to={{ pathname: "/login", state: { from: path } }} />)
//                 : (<Redirect to={{ pathname: "/kuber/login", state: { from: path } }} />)
//         }}
//         />
//     )
// }

// export default PrivateRoute

/**
 * @author Manimalarselvi
 * @since 1.0
 * @type React Component
 * @description Private router components
 */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import log from '../common/logger';
import LocalStorageHandler from '../modules/auth/LocalStorageHandler';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  log.info('*** PrivateRoute ***');
  const componentToRedirect = (props) => {
    if (LocalStorageHandler.getProductAuthenticated()) {
      if (LocalStorageHandler.getUserAuthenticated()) {
        return <Component {...props} />;
      }
      return <Redirect to={{ pathname: '/login', state: { from: path } }} />;
    }
    return <Redirect to={{ pathname: '/productLogin', state: { from: path } }} />;
  };
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => componentToRedirect(props)}
    />
  );
};

export default PrivateRoute;
