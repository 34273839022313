/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { Fragment } from 'react';
import {
  useExpanded, useFilters, usePagination, useSortBy, useTable,
} from 'react-table';
import {
  Button, Col, CustomInput, Input, Row, Table,
} from 'reactstrap';
import TableSticky from '../../../common/StickyTable/TableSticky';

const TableContainer = ({
  columns, data, onTogoNavigated, height, hasFooter, sticky,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    footerGroups,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const generateSortingIndicator = (column) => (column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '');

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };
  const isOnlineOrder = (onTogoNavigate, row) => {
    if (onTogoNavigate) {
      return (
        <tr onClick={(event) => onTogoNavigated(event, row.original)}>
          {row.cells.map((cell) => (
            <td {...cell.getCellProps()}>
              {cell.render('Cell')}
            </td>
          ))}
        </tr>
      );
    }
    return (
      <tr>
        {row.cells.map((cell) => (

          <td {...cell.getCellProps()}>
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
    );
  };
  if (sticky) {
    return (
      <TableSticky
        data={data}
        width="100%"
        hasFooter={hasFooter || false}
        className="s-table"
        showPagination
        height={height || '67vh'}
        columns={columns}
      />
    );
  }
  return (
    <>
      <Table hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                {isOnlineOrder((onTogoNavigated || null), row)}
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/* {renderRowSubComponent(row)} */}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td style={{ fontSize: '14px', fontWeight: 'bold' }} {...column.getFooterProps()}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </Table>

      <Row style={{ maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
        <Col md={3}>
          <Button
            color="primary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </Button>
          <Button
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {'<'}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          <span className="text-muted">
            Page
            {' '}

            <strong>
              {pageIndex + 1}
              {' '}
              of
              {' '}
              {' '}
              {pageOptions.length}
            </strong>
          </span>
        </Col>
        <Col md={2}>
          <div className="padiTransSecRight">
            <Input
              type="number"
              className="form-control1 text-muted"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </div>

        </Col>
        <Col md={2}>
          <div className="padiTransSecRight">
            <CustomInput
              id="pageSelect"
              type="select"
              className="form-control1 text-muted"
              value={pageSize}
              // style={{ color: '#444', backgroundColor: '#fff' }}
              onChange={onChangeInSelect}
            >
              {[20, 40, 60, 80, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show
                  {' '}
                  {pageSize}
                </option>
              ))}
            </CustomInput>
          </div>

        </Col>
        <Col md={3}>
          <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
            {'>'}
          </Button>
          <Button
            color="primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default TableContainer;
