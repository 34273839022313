/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/**
 * @author Manimalarselvi
 * @since 1.0
 * @type React Component
 * @description Location Config Create,Update screen components
 */

import React, {
  useEffect, useState, useMemo, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card, CardBody, CardHeader, CardTitle, Row, Col,
} from 'reactstrap';
import LocalStorageHandler from '../../../auth/LocalStorageHandler';
import Toast from '../../../../common/Toast';
import Loader from '../../../Alert/Loader';
import { toFloat } from '../../../../common/utils/number-utils';
import ServiceHandler from '../service-handler/ServiceHandler';
import TabDetails from '../view/TabDetails';
import EditBasicDetails from '../edit-form/EditBasicDetails';
import EditTaxDetails from '../edit-form/EditTaxDetails';
import EditTimeDetails from '../edit-form/EditTimeDetails';
import EditContactDetails from '../edit-form/EditContactDetails';
import EditOtherDetails from '../edit-form/EditOtherDetails';
import EditVoidReason from '../edit-form/EditVoidReason';
import AddUpdateTimeSlotCategoryModal from '../../../modals/AddUpdateTimeSlotCategoryModal';
import AddUpdateCategoryTimeTable from '../../../modals/AddUpdateTimeTableModal';
import AddVoidReason from '../../../modals/AddVoidReason';
import ActionButtons from '../edit-form/ActionButton';
import { concatValue, setSelectedWeekDays, setStatusMessage } from '../../menu-category/business/useBusiness';
import { getUpdateRequest, timeSlotPayload } from '../payload/Payload';
import ServerNotResponding from '../../../../common/server-not-responding/ServerNotResponding';
import EditLocationDetails from '../edit-form/EditLocationDetails';
import { appContext } from '../../../../common/appUseContext';
import TimeSlotDetails from '../edit-form/TimeSlotDetails';
import TimeTableDetails from '../edit-form/TimeTableDetails';
import AddJobRoles from '../../../modals/AddJobRolesModal';
import EditJobRoles from '../edit-form/EditJoRoles';
import PasscodeLayout from '../../../../common/PasscodeLayout';

const currencyTypeList = [
  { currencyId: 1, name: 'INR', value: 'inr' },
  { currencyId: 2, name: 'USD', value: 'usd' },
  { currencyId: 3, name: 'EUR', value: 'eur' },
  { currencyId: 4, name: 'JPY', value: 'jpy' },
  { currencyId: 5, name: 'GBP', value: 'gbp' },
  { currencyId: 6, name: 'CHF', value: 'chf' },
  { currencyId: 7, name: 'CAD', value: 'cad' },
  { currencyId: 8, name: 'ZAR', value: 'zar' },
  { currencyId: 9, name: 'AUD/NZD', value: 'aud/nzd' },
];
const LocationAddUpdateForm = (props) => {
  const { t } = useTranslation();
  const locationData = useContext(appContext).locationDetails;
  const {
    updateLocationDetails, updateTimeSlot, locationTimeSlot, updateEditingData,
    updateStkRemoveItem, updateStkRemoveAddOn, setTaxData,
  } = useContext(appContext);
  const [index, setIndex] = useState(-1);
  const [timeSlotModal, setTimeSlotModal] = useState(false);
  const [categoryTimeTableModal, setCategoryTimeTableModal] = useState(false);
  const [currentActive, setCurrentActive] = useState(1);
  const [imgFile, setImgFile] = useState(null);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);
  const [title, setTitle] = useState(t('genericTerms.create'));
  const [isLoading, setIsLoading] = useState(false);
  const [toastData, setToastData] = useState(null);
  const [voidReason, setVoidReason] = useState([]);
  const [voidDataList, setVoidData] = useState([]);
  const [voidModal, setVoidModal] = useState(false);
  const [timeSlot, setTimeSlot] = useState([]);
  const [timeTable, setTimeTable] = useState([]);
  const [jobRoleModal, setJobRoleModal] = useState(false);
  const [jobRoleList, setJobRoleList] = useState([]);
  const [jobRoleData, setJobRoleData] = useState([]);

  const [slotDetails, setSlotDetails] = useState(null);
  const [timeTableDetails, setTimeTableDetails] = useState(null);

  const [approveModal, setApproveModal] = useState(false);
  const [isChangeZeroTaxFree, setIsChangeZeroTaxFree] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    cuisineType: '',
    weightUnits: '',
    locationCurrencyType: '',
    businessName: '',
    locationVersion: '',
    locationType: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    zipCode: '',
    city: '',
    CityState: '',
    county: '',
    country: '',
    stateTax: '',
    cityTax: '',
    contactNumber: '',
    geoCode: '',
    // smsNumber: '',
    emailId: '',
    isConfirmAmount: false,
    gratuityPercentage: '',
    locationActive: false,
    noOfGuest: '',
    maxCRAmount: '',
    rewardsPointsValue: '',
    deliveryCharges: '',
    paymentVpLinkValidityTime: '',
    rwdMinPoints: '',
    awsUrl: '',
    expDays: '',
    localhostIp: '',
    localhostPort: '',
    status: '',
    openTimeData: '00:00:00',
    closeTimeData: '00:00:00 ',
    taxFreeDate: '',
    inventoryManagementActiveStatus: true,
    latitude: '',
    longitude: '',
    isZeroTaxForOosTogo: false,
  });
  // useMemo
  const timeSlotColumns = useMemo(() => [
    { Header: 'Slot Name', accessor: 'slotName', sortable: false },
    { Header: 'Slot Start Time', accessor: 'slotStartTime' },
    { Header: 'Slot Stop Time', accessor: 'slotStopTime' },
    { Header: 'Actions', accessor: 'actions' },
  ], []);

  const timeTableColumns = useMemo(() => [
    { Header: 'Time Table Repeat Type', accessor: 'timeTableRepeatType', sortable: false },
    { Header: 'Time Table Recurring', accessor: 'days' },
    { Header: 'Actions', accessor: 'actions' },
  ], []);
  const [errorText, setErrorText] = useState('');
  const hideAlert = () => setErrorText('');
  const removeLocalData = () => {
    updateStkRemoveItem(null);
    updateStkRemoveAddOn(null);
    LocalStorageHandler.userLogout();
  };

  const onShowApproveForm = () => setApproveModal(!approveModal);
  const onSubmitAdminPin = () => {
    locationUpdateApi();
  };
  const cancelJobDetails = () => {
    setIndex(-1);
    jobRoleToggleForm();
  };
  const jobRoleToggleForm = () => setJobRoleModal(!jobRoleModal);
  const submitJobDetails = (details) => {
    const data = details;
    data.status = 1;
    createJobRole(data);
    cancelJobDetails();
  };
  const userLogout = () => removeLocalData();
  const setFormData = (data) => {
    const slotList = locationTimeSlot;
    const timeTableList = data?.locationTimeTable;
    setLocationDetails({
      ...locationDetails,
      cuisineType: data.locationCuisineType,
      weightUnits: data.locationWeightUnits,
      locationCurrencyType: data.locationCurrencyType,
      businessName: data.locationBusinessName,
      locationVersion: data.locationVersion,
      locationType: data.locationType,
      addressLine1: data.locationAddrLine1,
      addressLine2: data.locationAddrLine2,
      addressLine3: data.locationAddrLine3,
      addressLine4: data.locationAddrLine4,
      zipCode: data.locationAddrZipCode,
      city: data.locationAddrCity,
      CityState: data.locationAddrState,
      county: data.locationAddrCounty,
      country: data.locationAddrCountry,
      stateTax: data.locationStateTax,
      cityTax: data.locationCityTax,
      contactNumber: data.locationContactNumber,
      geoCode: data.locationGeoCode,
      // smsNumber: data.locationSmsNumber,
      emailId: data.locationEmail,
      isConfirmAmount: data.isConfirmAmount !== 0,
      gratuityPercentage: data.locationGratuityPercentage,
      noOfGuest: data.locationGratuityNoOfGuests,
      locationActive:
        data.isLocationActive === 1,
      maxCRAmount: toFloat(data.maxCrAmount),
      orderPrepBufferTime: data.orderPrepBufferTime,
      rewardsPointsValue: data.rewardsPointsValue,
      deliveryCharges: toFloat(data.deliveryCharges),
      paymentVpLinkValidityTime:
        data.paymentVplinkValidityTime,
      rwdMinPoints: data.minRedeemPoints,
      expDays: data.rewardExpiryDays,
      awsUrl: data.awsUrl,
      localhostIp: data.localhostIp,
      localhostPort: data.localhostPort,
      status: data.hostFlag === 1,
      taxFreeDate: data.locationTaxFreeDate,
      inventoryManagementActiveStatus: data.inventoryMmtStatus ? data.inventoryMmtStatus : false,
      latitude: data?.lat,
      longitude: data?.long,
      isZeroTaxForOosTogo: data?.isZeroTaxForOosTogo,
    });
    setImgPreviewUrl(data.locationLogo);
    setVoidReason(data?.voidReasons);
    setJobRoleList(data?.jobRoleDetails);
    let values = timeTableList?.length > 0
      ? timeTableList : [];
    if (values !== null && values.length > 0) values = setSelectedWeekDays(values);
    setTimeTable((timeTableList !== null && timeTableList.length > 0)
      ? timeTableList : []);
    populateSlotTableData((slotList !== null && slotList.length > 0)
      ? slotList : []);
    populateTimeTableData((timeTableList !== null && timeTableList.length > 0) ? values : []);

    // let values = (timeTableList && timeTableList?.length > 0)
    //   ? JSON.parse(timeTableList) : [];
    // if (values !== null && values.length > 0) values = setSelectedWeekDays(values);

    // setTimeTable((timeTableList && timeTableList.length > 0)
    //   ? JSON.parse(timeTableList) : []);
    // populateSlotTableData((slotList && slotList.length > 0)
    //   ? JSON.parse(slotList) : []);
    // populateTimeTableData((timeTableList && timeTableList.length > 0) ? values : []);
  };

  const getLocationData = async () => {
    if (locationData !== null) setFormData(locationData);
  };
  useEffect(() => {
    if (props.match.params.locationId !== undefined) {
      setTitle(t('genericTerms.update'));
      getLocationData();
    }
  }, []);

  const toggleForm = () => {
    setVoidModal(!voidModal);
  };
  const deleteReason = (position) => {
    const list = [...voidReason];
    const pushData = [...voidDataList];
    list[position].isFlag = 2;
    pushData.push(list[position]);
    list.splice(position, 1);
    setVoidReason(list);
    setVoidData(pushData);
  };
  const deleteJobRole = (position) => {
    const list = [...jobRoleList];
    const pushData = [...jobRoleData];
    list[position].status = 2;
    pushData.push(list[position]);
    list.splice(position, 1);
    setJobRoleList(list);
    setJobRoleData(pushData);
  };

  const createJobRole = (data) => {
    const list = [...jobRoleData];
    const jobList = [...jobRoleList];
    list.push(data);
    jobList.push(data);
    setJobRoleData(list);
    setJobRoleList(jobList);
    setJobRoleModal(!jobRoleModal);
  };
  const createReason = (data) => {
    const list = [...voidDataList];
    const voidReasonList = [...voidReason];
    list.push(data);
    voidReasonList.push(data);
    setVoidData(list);
    setVoidReason(voidReasonList);
    setVoidModal(!voidModal);
  };
  const timeTableValidation = (message) => {
    const result = setStatusMessage(400, message);
    setNotification(result);
  };
  const clickOnEditSlot = (el, item, position) => {
    setIndex(position);
    setSlotDetails(item);
    setTimeSlotModal(true);
  };
  const clickOnEditTimeTable = (el, item, position) => {
    setIndex(position);
    setTimeTableDetails(item);
    setCategoryTimeTableModal(true);
  };
  const populateSlotTableData = (tableData) => {
    let data = [];
    if (tableData.length > 0) {
      data = tableData.map((el, key) => ({
        slotId: el.slotId,
        slotName: el.slotName,
        slotStartTime: el.slotStartTime,
        slotStopTime: el.slotStopTime,
        actions: (
          <div>
            <i
              onClick={() => deleteSlot(tableData, key)}
              className="fas fa-trash text-danger cursor-pointer pt-2 pb-2 pl-3 pr-3"
            />
            &nbsp;&nbsp;&nbsp;
            <i
              className="fas fa-edit text-blue cursor-pointer pt-2 pb-2 pl-3 pr-3"
              onClick={(e) => clickOnEditSlot(e, el, key)}
            />
          </div>
        ),
      }));
    }
    setTimeSlot(data);
  };
  const populateTimeTableData = (tableData) => {
    let updateData = [];
    if (tableData.length > 0) {
      updateData = tableData.map((el, key) => ({
        timeTableRepeatType: el.timeTableRepeatType,
        timeTableId: el.timeTableId,
        days: el.days,
        timeTableRecurring: el.timeTableRecurring,
        actions: (
          <div>
            <i
              onClick={() => deleteTimeTable(key)}
              className="fas fa-trash text-danger cursor-pointer pt-2 pb-2 pl-3 pr-3"
            />
            &nbsp;&nbsp;&nbsp;
            <i
              onClick={(e) => clickOnEditTimeTable(e, el, key)}
              className="fas fa-edit text-blue cursor-pointer pt-2 pb-2 pl-3 pr-3"
            />
          </div>
        ),
      }));
    }

    setTimeTable(updateData);
  };
  const deleteSlot = (listOfArray, position) => {
    const req = timeSlotPayload('delete', listOfArray[position]);
    deleteTimeSlot(req);
    // const list = listOfArray;
    // list.splice(position, 1);
    // populateSlotTableData(list);
  };
  const deleteTimeTable = (position) => {
    const list = [...timeTable];
    list.splice(position);
    populateTimeTableData(list);
  };
  const updatedSlotDetails = (data) => {
    const slotPayload = timeSlotPayload(index === -1 ? 'create' : 'update', data);
    // Edit or new
    if (index === -1) {
      createSlot(slotPayload);
    } else {
      // list[index] = data;
      // populateSlotTableData(list);
      updateSlot(slotPayload, data);
    }

    setSlotDetails(null);
    setTimeSlotModal(false);
  };
  const updatedTimeTableDetails = (data) => {
    const list = [...timeTable];
    if (list.length > 0) {
      const findInd = list.findIndex((item) => item.timeTableRepeatType === data.timeTableRepeatType);
      if (index === -1) {
        if (findInd > -1) {
          timeTableValidation(t('category.repeatTypeDuplicated'));
        } else {
          timeTableValidation(t('category.timeTableMoreThanRecordWarn'));
        }
        return;
      }
    }

    // Edit or new
    if (index === -1) {
      const value = concatValue(list, data);
      const timeTableList = setSelectedWeekDays(value);
      populateTimeTableData(timeTableList);
    } else {
      list[index] = data;
      const timeTableList = setSelectedWeekDays(list);
      populateTimeTableData(timeTableList);
    }
    setCategoryTimeTableModal(!categoryTimeTableModal);
    setTimeTableDetails(null);
  };
  const handleTaxFreeDateChange = (e) => {
    setLocationDetails({ ...locationDetails, taxFreeDate: e._d });
  };
  const handleToggleValue = (e) => {
    setLocationDetails({ ...locationDetails, status: e.target.checked });
  };
  const setNotification = (result) => {
    setToastData(result);
  };
  const setMessage = (errCode, message) => {
    const result = { status: errCode, message };
    setNotification(result);
  };

  const createSlot = async (req) => {
    try {
      const result = await ServiceHandler.createTimeSlot(props, req);
      setNotification(result);
      if (result.status === 200) resetData(result.data);
    } catch {
      setErrorText(t('genericTerms.serverNotResponding'));
    }
  };
  const resetData = (result) => {
    updateTimeSlot(result);
    setTimeSlot(result);
    setTimeSlotModal(!timeSlotModal);
    populateSlotTableData(result);
  };
  const updateSlot = async (req) => {
    try {
      const result = await ServiceHandler.updateTimeSlot(props, req);
      setNotification(result);

      if (result.status === 200) resetData(result.data);
    } catch {
      setErrorText(t('genericTerms.serverNotResponding'));
    }
  };

  const deleteTimeSlot = async (req) => {
    try {
      const result = await ServiceHandler.deleteTimeSlotDetails(props, req);
      if (result.status === 200) {
        updateTimeSlot(result.data);
        setNotification(result); setTimeSlot(result.data);
        populateSlotTableData(result.data);
      } else {
        setNotification(result);
      }
    } catch {
      setErrorText(t('genericTerms.serverNotResponding'));
    }
  };
  const chooseLogo = (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setMessage(400, t('errorMessages.plzUpdaloadImg'));
      return false;
    }
    reader.onloadend = () => {
      setImgPreviewUrl(reader.result);
      setImgFile(file);
    };
    reader.readAsDataURL(file);
  };

  const onLogoImgCancel = () => {
    deleteLogo();
  };

  const validateAlphaNumNoDigits = (value) => {
    const alphaNumericRegex = /^(?=.*)(?=.*[a-zA-Z]).{2,}$/;
    return alphaNumericRegex.test(value);
  };
  const validateIpAddress = (value) => {
    const ipAddressRegex = /^[\w+=:#@/\-,\.][\w+=:#@/\-,\.\s]+[\w+=:#@/\-,\.]$/;
    return ipAddressRegex.test(value);
  };
  const formTaxData = (details) => {
    setTaxData({
      locationCityTax: details.locationCityTax,
      locationGratuityNoOfGuests: details.locationGratuityNoOfGuests,
      locationGratuityPercentage: details.locationGratuityPercentage,
      locationStateTax: details.locationStateTax,
      locationTaxFreeDate: details.locationTaxFreeDate,
    });
  };

  const updateLocationFormDetails = async (request) => {
    try {
      const result = await ServiceHandler.updateLocationDetails(request, props);
      setIsLoading(false);
      setNotification(result);
      if (result.status === 200) {
        formTaxData(result.data.locationConfigDetails);
        if (isChangeZeroTaxFree) onShowApproveForm();
        if (imgFile !== null) {
          logoUpload(result.data.locationConfigDetails.locationId, result.data.locationConfigDetails);
        } else {
          updateLocationDetails(result.data.locationConfigDetails);
          onNavigate();
        }
      } else {
        setNotification(result);
      }
    } catch {
      setErrorText(t('genericTerms.serverNotResponding'));
    }
  };

  const onFormSubmitted = (el) => {
    el.preventDefault();

    const regEx_OnlyContactNumbers = new RegExp(/^(0|[1-9][0-9]*)$/);
    const regEx_Email = new RegExp(
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    );

    if (locationDetails.businessName === '') {
      setMessage(400, t('errorMessages.businessNameMandatory'));
    } else if (!validateAlphaNumNoDigits(locationDetails.businessName)) {
      setMessage(400, t('errorMessages.bussinessNameNotValid'));
    } else if (locationDetails.locationType === '') {
      setMessage(400, t('errorMessages.locationTypeMandatory'));
    } else if (!validateAlphaNumNoDigits(locationDetails.locationType)) {
      setMessage(400, t('errorMessages.locationTypeNotValid'));
    } else if (locationDetails.locationVersion === '') {
      setMessage(400, t('errorMessages.locationVersionMandatory'));
    } else if (locationDetails.locationCurrencyType === '') {
      setMessage(400, t(
        'errorMessages.currencyTypeMandatory',
      ));
    } else if (locationDetails.emailId === '') {
      setMessage(400, t('errorMessages.emailMandatory'));
    } else if (!regEx_Email.test(locationDetails.emailId)) {
      setMessage(400, t('errorMessages.emailnotValid'));
    } else if (locationDetails.contactNumber === '') {
      setMessage(400, t('errorMessages.contactNumberMandatory'));
    } else if (locationDetails.geoCode === null || locationDetails.geoCode === undefined || locationDetails.geoCode === '') {
      setMessage(400, t('errorMessages.geoCodeMandatory'));
    } else if (!locationDetails.geoCode.startsWith('+')) {
      setMessage(400, t('errorMessages.geoCodeStartsWith'));
    } else if (isNaN(locationDetails.geoCode)) {
      setMessage(400, t('errorMessages.geoCodeNumbersOnly'));
    } else if (
      locationDetails.contactNumber.length < 8
      || locationDetails.contactNumber.length > 13
      || !regEx_OnlyContactNumbers.test(locationDetails.contactNumber)
    ) {
      setMessage(400, t('errorMessages.contactNumLimit'));
    } else if (locationDetails.cityTax === '') {
      setMessage(400, t('errorMessages.citytaxMandatory'));
    } else if (locationDetails.stateTax === '') {
      setMessage(400, t('errorMessages.stateTaxMandatory'));
    } else if (locationDetails.gratuityPercentage === '') {
      setMessage(400, t('errorMessages.gratuityMandatory'));
    } else if (locationDetails.noOfGuest === '') {
      setMessage(400, t('errorMessages.noGuestMandatory'));
    } else if (locationDetails.taxFreeDate === '') {
      setMessage(400, t('errorMessages.taxfreeMandatory'));
    } else if (locationDetails.cuisineType === '') {
      setMessage(400, t('errorMessages.cuisineMandatory'));
    } else if (locationDetails.weightUnits === '') {
      setMessage(400, t('errorMessages.weightMandatory'));
    } else if (locationDetails.maxCRAmount === '') {
      setMessage(400, t('errorMessages.crMandatory'));
    } else if (locationDetails.orderPrepBufferTime === '') {
      setMessage(400, t('errorMessages.ordPreptime'));
    } else if (locationDetails.rewardsPointsValue === '') {
      setMessage(400, t('errorMessages.rewardPointsMandatory'));
    } else if (locationDetails.deliveryCharges === '') {
      setMessage(400, t('errorMessages.deliverChangeMandatory'));
    } else if (locationDetails.paymentVpLinkValidityTime === '') {
      setMessage(400, t('errorMessages.virtualPaymentLinkMandatory'));
    } else if (locationDetails.latitude === undefined || locationDetails.latitude === null || locationDetails.latitude === '') {
      setMessage(400, t('errorMessages.latitudeMandatory'));
    } else if (locationDetails.longitude === undefined || locationDetails.longitude === null || locationDetails.longitude === '') {
      setMessage(400, t('errorMessages.longitudeMandatory'));
    } else if (locationDetails.addressLine1 === '') {
      setMessage(400, t('errorMessages.addressMandatory'));
    } else if (locationDetails.city === '') {
      setMessage(400, t('errorMessages.cityMandatory'));
    } else if (locationDetails.county === '') {
      setMessage(400, t('errorMessages.countyMandatory'));
    } else if (locationDetails.CityState === '') {
      setMessage(400, t('errorMessages.stateMandatory'));
    } else if (locationDetails.country === '') {
      setMessage(400, t('errorMessages.countryMandatory'));
    } else if (locationDetails.zipCode === '') {
      setMessage(400, t('errorMessages.zipcodeMandatory'));
    } else if (locationDetails.expDays === '') {
      setMessage(400, t('errorMessages.noExpiryMandatory'));
    } else if (locationDetails.rwdMinPoints === '') {
      setMessage(400, t('errorMessages.minRewardMandatory'));
    } else if (locationDetails.status === false && locationDetails.localhostIp === '') {
      setMessage(400, t('errorMessages.localIpAddrMandatory'));
    } else if (locationDetails.status === false && locationDetails.localhostPort === '') {
      setMessage(400, t('errorMessages.localPortNumberMandatory'));
    } else if (locationDetails.status === true && locationDetails.awsUrl === '') {
      setMessage(400, t('errorMessages.awsUrlMandatory'));
    } else if (locationDetails.status === true && !validateIpAddress(locationDetails.awsUrl)) {
      setMessage(400, t('errorMessages.awsIpAddrFormatMandatory'));
    } else if (imgPreviewUrl === null) {
      setMessage(400, t('errorMessages.logoMandatory'));
    } else if (locationDetails.locationActive === false) {
      setMessage(400, t('errorMessages.activeFieldMandatory'));
    } else if (voidReason.length > 6) {
      setMessage(400, t('errorMessages.voidReasonMaxLimit'));
    } else if (isChangeZeroTaxFree) {
      onShowApproveForm();
    } else {
      locationUpdateApi();
    }
  };

  const locationUpdateApi = () => {
    let locationId = 0;
    if (props.match.params.locationId !== undefined) {
      locationId = props.match.params.locationId;
    }
    const REQ = getUpdateRequest(locationId, locationDetails, voidDataList, timeSlot, timeTable, jobRoleData);
    // return;
    setIsLoading(true);

    if (locationId !== 0) {
      updateLocationFormDetails(REQ);
    }
  };
  const onNavigate = () => {
    updateEditingData(false);
    props.history.push('/admin/location/config/list');
  };

  const logoUpload = async (locationId, data) => {
    try {
      const details = data;
      const formData = new FormData();
      formData.append('file', imgFile);
      setIsLoading(true);
      const result = await ServiceHandler.logoUpload(locationId, formData, props);
      setIsLoading(false);
      setNotification(result);
      details.locationLogo = result.data.locationLogo;
      if (result.status === 200) {
        updateLocationDetails(details);
        onNavigate();
      } else if (result.status === 400) {
        userLogout();
        props.history.push('/login');
      }
    } catch {
      setIsLoading(false);
      setErrorText(t('genericTerms.serverNotResponding'));
    }
  };
  const timeSlotToggleForm = (position) => {
    setIndex(position);
    if (timeSlotModal === true) setIndex(-1);

    setTimeSlotModal(!timeSlotModal);
  };
  const categoryTimeTableToggleForm = (position) => {
    setIndex(position);
    setCategoryTimeTableModal(!categoryTimeTableModal);
  };
  const handleFormChange = (event) => {
    if (event.target.name === 'isZeroTaxForOosTogo') {
      setIsChangeZeroTaxFree(true);
    }
    setLocationDetails({
      ...locationDetails,
      [event.target.name]: (event.target.name === 'locationActive'
        || event.target.name === 'isConfirmAmount' || event.target.name === 'isZeroTaxForOosTogo') ? event.target.checked : event.target.value,
    });
  };
  const inventoryManagementToggleHandler = () => {
    const status = !locationDetails.inventoryManagementActiveStatus;
    setLocationDetails({ ...locationDetails, inventoryManagementActiveStatus: status });
  };
  const onChangeTabHandler = (value) => {
    setCurrentActive(value);
  };
  const selectCurrencyType = (event) => {
    setLocationDetails({ ...locationDetails, locationCurrencyType: event.target.value });
  };

  const deleteLogo = async () => {
    try {
      const details = locationData;
      setIsLoading(true);
      const result = await ServiceHandler.deleteLogo(locationData?.locationId, props);
      setIsLoading(false);
      setNotification(result);
      if (result.status === 200) {
        details.locationLogo = null;
        updateLocationDetails(details);
        setImgPreviewUrl(null);
        setImgFile(null);
      } else if (result.status === 400) {
        userLogout();
        props.history.push('/login');
      }
    } catch {
      setIsLoading(false);
      setErrorText(t('genericTerms.serverNotResponding'));
    }
  };

  return (
    <div className="spacing-20">
      {isLoading && <Loader onCancel={() => setIsLoading(false)} />}
      {errorText !== '' && <ServerNotResponding errorText={errorText} hideAlert={hideAlert} />}
      <Toast
        toastData={toastData}
      />
      <PasscodeLayout
        approveModal={approveModal}
        onShowApproveForm={onShowApproveForm}
        handleSubmit={onSubmitAdminPin}
      />
      <AddJobRoles
        index={-1}
        modalShow={jobRoleModal}
        jobRoleRatesToggleForm={jobRoleToggleForm}
        submitJobDetails={submitJobDetails}
        cancelJobDetails={cancelJobDetails}
        jobRoleList={jobRoleList}
      />
      <AddVoidReason
        voidModal={voidModal}
        toggleForm={toggleForm}
        createReason={createReason}
      />
      <AddUpdateTimeSlotCategoryModal
        updateSlotDetails={updatedSlotDetails}
        slotDetails={slotDetails}
        index={index}
        modalShow={timeSlotModal}
        timeSlotToggleForm={timeSlotToggleForm}
      />
      <AddUpdateCategoryTimeTable
        props={props}
        index={index}
        timeTableDetails={timeTableDetails}
        modalShow={categoryTimeTableModal}
        updatedTimeTableDetails={updatedTimeTableDetails}
        timeTableToggleForm={categoryTimeTableToggleForm}
      />
      <Card>
        <CardHeader>
          <CardTitle>
            {title === 'Create'
              ? `${t('genericTerms.add')} ${t('genericTerms.location')}`
              : `${t('genericTerms.edit')
              } ${t('genericTerms.location')}`}
            {' '}
          </CardTitle>
          {' '}
        </CardHeader>
        <CardBody>
          <TabDetails
            currentActive={currentActive}
            onChangeTabHandler={onChangeTabHandler}
            timeSlotToggleForm={timeSlotToggleForm}
            categoryTimeTableToggleForm={categoryTimeTableToggleForm}
          />

          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="bg-white1">
                {/* <div className="card-body"> */}
                <Row>
                  <Col lg="12" md={12} sm={12}>
                    {currentActive === 1
                      && (
                        <EditBasicDetails
                          locationDetails={locationDetails}
                          currencyTypeList={currencyTypeList}
                          handleFormChange={handleFormChange}
                          selectCurrencyType={selectCurrencyType}
                          handleToggleValue={handleToggleValue}
                          chooseLogo={chooseLogo}
                          imgPreviewUrl={imgPreviewUrl}
                          onLogoImgCancel={onLogoImgCancel}
                        />
                      )}
                    {currentActive === 2
                      && (
                        <EditTaxDetails
                          locationDetails={locationDetails}
                          handleFormChange={handleFormChange}
                          handleTaxFreeDateChange={handleTaxFreeDateChange}
                        />
                      )}
                    {currentActive === 3
                      && (
                        <EditTimeDetails
                          locationDetails={locationDetails}
                          handleFormChange={handleFormChange}

                        />
                      )}
                    {currentActive === 4
                      && (
                        <EditContactDetails
                          locationDetails={locationDetails}
                          handleFormChange={handleFormChange}
                          inventoryManagementToggleHandler={inventoryManagementToggleHandler}
                          handleToggleValue={handleToggleValue}
                          chooseLogo={chooseLogo}
                          imgPreviewUrl={imgPreviewUrl}
                          onLogoImgCancel={onLogoImgCancel}
                        />
                      )}
                    {currentActive === 5
                      && (
                        <EditOtherDetails
                          locationDetails={locationDetails}
                          handleFormChange={handleFormChange}
                          handleToggleValue={handleToggleValue}
                          inventoryManagementToggleHandler={inventoryManagementToggleHandler}

                        />
                      )}
                    {currentActive === 6
                      && (
                        <EditVoidReason
                          locationDetails={locationDetails}
                          reasonList={voidReason}
                          deleteReason={deleteReason}
                          toggleForm={toggleForm}
                        />
                      )}
                    {currentActive === 9
                      && (
                        <EditLocationDetails
                          locationDetails={locationDetails}
                          handleFormChange={handleFormChange}

                        />
                      )}

                    <Row>
                      <Col md={12}>

                        {
                          currentActive === 7 && (

                            <div>
                              <EditTimeDetails
                                locationDetails={locationDetails}
                                handleFormChange={handleFormChange}

                              />
                              <TimeSlotDetails timeSlotToggleForm={timeSlotToggleForm} timeSlotColumns={timeSlotColumns} timeSlot={timeSlot} />

                              <TimeTableDetails categoryTimeTableToggleForm={categoryTimeTableToggleForm} timeTableColumns={timeTableColumns} timeTable={timeTable} />

                            </div>
                          )
                        }
                        {
                          currentActive === 8 && (
                            <div>
                              <EditJobRoles
                                locationDetails={locationDetails}
                                jobList={jobRoleList}
                                deleteJobRole={deleteJobRole}
                                jobRoleModalToggle={jobRoleToggleForm}
                              />
                              {/* <h4>{t('weekDays.tableDetails')}</h4> */}
                              {/* <TableComponent columns={timeTableColumns} data={timeTable} /> */}

                            </div>
                          )
                        }
                      </Col>
                    </Row>

                    <ActionButtons
                      title={title}
                      onFormSubmitted={onFormSubmitted}
                      onNavigate={onNavigate}
                    />

                  </Col>
                </Row>
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default LocationAddUpdateForm;
