import React from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';

const ServerNotResponding = ({ errorText, hideAlert }) => (
  <ReactBSAlert
    type="error"
    style={{ display: 'block', marginTop: '-100px' }}
    title={errorText}
    onConfirm={() => hideAlert()}
    confirmBtnBsStyle="primary"
    btnSize=""
  />
);

export default ServerNotResponding;
