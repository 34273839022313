import ApiConfig from '../../config/ApiConfig';
import LocalStorageHandler from '../auth/LocalStorageHandler';

const method = {
  POST: 'post', GET: 'get', PUT: 'put', DELETE: 'delete',
};
const toJsonStr = (val) => JSON.stringify(val);

const productToken = LocalStorageHandler.getProductToken();

const handleRequest = (apiKey, methodType, bodyType) => {
  const getWithCredentialHeader = {
    'Content-Type': 'application/json',
    Authorization: LocalStorageHandler.getUserToken(),
  };
  return fetch(ApiConfig.API_BASE + apiKey, {
    method: methodType,
    headers: getWithCredentialHeader,
    body: bodyType,
  // eslint-disable-next-line no-console
  }).catch((err) => console.log('ERROR 11', err));
};

const handleImgUploadRequest = (apiKey, methodType, bodyType) => fetch(`${ApiConfig.API_BASE}${apiKey}`, {
  method: methodType,
  headers: { Authorization: LocalStorageHandler.getUserToken() },
  body: bodyType,
});

const AdminServices = {

  // If invalid User redirect to login
  authUser(errors, props) {
    if (errors !== undefined && errors === 'noauth') LocalStorageHandler.clearCache(() => props.history.push('/login'));
  },

  // Employee
  employeeImageUpload(empId, request) { return handleImgUploadRequest(`employee/img/update/${empId}`, method.PUT, request); },
  employeeDocumentUpload(empId, request) { return handleImgUploadRequest(`employee/document/update/${empId}`, method.PUT, request); },
  createEmployeeDetails(req) { return handleRequest('employee/create', method.POST, toJsonStr(req)); },
  updateEmployeeDetails(req) { return handleRequest('employee/update', method.PUT, toJsonStr(req)); },
  deleteEmployeeDetails(req) { return handleRequest('employee/delete', method.GET, toJsonStr(req)); },
  getEmployeeList() { return handleRequest('employee/list', method.GET, null); },
  getGuestModeEmployeeList() { return handleRequest('guestMode/employee/list', method.GET, null); },
  getEmployeeLocationList() { return handleRequest('employee/location/list', method.GET, null); },
  deleteEmployee(empId) { return handleRequest(`employee/delete/${empId}`, method.DELETE, null); },
  getEmployeeViewDetails(empId) { return handleRequest(`employee/view/${empId}`, method.GET, null); },
  resetPassword(request) { return handleRequest('employee/password/reset', method.PUT, toJsonStr(request)); },
  getEmployeeRoleList() { return handleRequest('empType', method.GET, null); },
  getEmployeeModeList() { return handleRequest('empMode', method.GET, null); },
  getEmployeeModuleList(empType) { return handleRequest(`moduleSelectByType/${empType}`, method.GET, null); },
  createEmployeeJobRole(req) { return handleRequest('employee/wages/create', method.POST, toJsonStr(req)); },
  updateEmployeeJobRole(req) { return handleRequest('employee/wages/update ', method.PUT, toJsonStr(req)); },
  // Clock in / Clock out
  employeeStatusChange(req) { return handleRequest('clock/in', method.POST, toJsonStr(req)); },
  getEmployeeClockInDetails(empId, clockInDate) { return handleRequest(`clock/in/onload/${empId}/${clockInDate}`, method.GET, null); },
  employeeRoleUpdate(request) { return handleRequest('update/employee/role', method.POST, toJsonStr(request)); },
  clockInUpdate(request) { return handleRequest('clock/in/update', method.POST, toJsonStr(request)); },
  clockInClockOutPrint(empId, clockInDate) { return handleRequest(`clock/in/print/${empId}/${clockInDate}`, method.GET, null); },
  // Vendor
  createVendorDetails(req) { return handleRequest('vendor/create', method.POST, toJsonStr(req)); },
  updateVendorDetails(req) { return handleRequest('vendor/update', method.PUT, toJsonStr(req)); },
  getVendorList() { return handleRequest('vendor/list', method.GET, null); },
  getVendorViewDetails(vendorId) { return handleRequest(`vendor/view/${vendorId}`, method.GET, null); },
  deleteVendor(vendorId) { return handleRequest(`vendor/delete/${vendorId}`, method.DELETE, null); },
  vendorPaymentHistory(vendorId) { return handleRequest(`vendor/payment/history/${vendorId}`, method.GET, null); },
  vendorImageUpload(vendorId, request) { return handleImgUploadRequest(`vendor/img/update/${vendorId}`, method.PUT, request); },

  // Category
  categoryImageUpload(categoryId, request) { return handleImgUploadRequest(`category/img/update/${categoryId}`, method.PUT, request); },
  createCategory(request) { return handleRequest('category/create', method.POST, toJsonStr(request)); },
  updateCategory(request) { return handleRequest('category/update', method.PUT, toJsonStr(request)); },
  deleteCategory(request) { return handleRequest(`category/delete/${request.menuCatgId}`, method.DELETE); },
  getCategoryList() { return handleRequest('category/list', method.GET, null); },
  getCategoryViewDetails(categoryId) { return handleRequest(`category/view/${categoryId}`, method.GET, null); },
  bulkCategoryCreate(request) { return handleRequest('bulk/category/create', method.POST, toJsonStr(request)); },
  deleteCategoryImg(categoryId) { return handleRequest(`category/img/delete/${categoryId}`, method.DELETE); },

  // MenuType
  createMenuType(request) { return handleRequest('menu-type/create', method.POST, toJsonStr(request)); },
  updateMenuType(request) { return handleRequest('menu-type/update', method.PUT, toJsonStr(request)); },
  deleteMenuType(request) { return handleRequest(`menu-type/delete/${request.menuTypeId}`, method.DELETE); },
  bulkMenuTypeCreate(request) { return handleRequest('menu-type/bulk/create', method.POST, toJsonStr(request)); },
  getMenuTypeList() { return handleRequest('menutype/list', method.GET, null); },
  deleteAllergyImg(menuTypeId) { return handleRequest(`menuType/icon/delete//${menuTypeId}`, method.DELETE); },
  updateAllergyImg(menuTypeId, request) { return handleImgUploadRequest(`menuType/icon/update/${menuTypeId}`, method.POST, request); },

  // Virtual printers
  getVirtualPrinterList() { return handleRequest('virtual/printer/list', method.GET, null); },
  createVirtualPrinter(request) { return handleRequest('virtual/printer/create', method.POST, toJsonStr(request)); },
  updateVirtualPrinter(request) { return handleRequest('virtual/printer/update', method.POST, toJsonStr(request)); },
  deleteVirtualPrinter(request) { return handleRequest(`virtual/printer/delete/${request.virtualPrinterId}`, method.DELETE, null); },
  getPrinterDriverList() { return handleRequest('virtual/printer/drivers', method.GET, null); },
  getPrinterTypeList() { return handleRequest('virtual/printer/print/types  ', method.GET, null); },
  testPrint(request) { return handleRequest('virtual/printer/test/print', method.POST, toJsonStr(request)); },

  // Menu
  createMenu(request) { return handleRequest('menu/create', method.POST, toJsonStr(request)); },
  deleteMenu(request) { return handleRequest(`menu/delete/${request.menuId}/${request.menuName}`, method.DELETE); },
  getMenuViewDetails(menuId) { return handleRequest(`menu/view/${menuId}`, method.GET, null); },
  getMenuList() { return handleRequest('menu/list', method.GET, null); },
  updateMenu(request) { return handleRequest('update/menu', method.POST, toJsonStr(request)); },
  menuImageUpload(menuId, request) { return handleImgUploadRequest(`menu/img/update/${menuId}`, method.POST, request); },

  menuImageDelete(menuId) { return handleRequest(`menu/img/delete/${menuId}`, method.DELETE); },

  // Menu Group
  createMenuGroup(request) { return handleRequest('menuGroups/create', method.POST, toJsonStr(request)); },
  deleteMenuGroup(menuGroupId) { return handleRequest(`menuGroups/delete/${menuGroupId}`, method.DELETE); },
  // getMenuGroupViewDetails(menuId) { return handleRequest(`menu/view/${menuId}`, method.GET, null); },
  getMenuGroupList() { return handleRequest('menuGroups/list', method.GET, null); },
  updateMenuGroup(request) { return handleRequest('menuGroups/update', method.POST, toJsonStr(request)); },
  menuGroupImageUpload(menuId, request) { return handleImgUploadRequest(`menuGroups/img/update/${menuId}`, method.POST, request); },

  menuGroupImageDelete(menuId) { return handleRequest(`menuGroups/img/delete/${menuId}`, method.DELETE); },
  // AddOn details
  createAddOn(request) { return handleRequest('addOn/create', method.POST, toJsonStr(request)); },
  updateAddOn(request) { return handleRequest('addOn/update', method.PUT, toJsonStr(request)); },
  deleteAddOn(request) { return handleRequest(`addOn/delete/${request.addOnId}`, method.DELETE); },
  bulkAddOnCreate(request) { return handleRequest('bulk/addOn/create', method.POST, toJsonStr(request)); },
  getAddOnList() { return handleRequest('addOn/list', method.GET, null); },
  uploadAddOnImg(addOnId, request) { return handleImgUploadRequest(`addon/img/update/${addOnId}`, method.POST, request); },
  deleteAddOnImage(addOnId) { return handleRequest(`addon/img/delete/${addOnId}`, method.DELETE); },

  // Add on Group details
  createAddonGroup(request) { return handleRequest('addonGroups/create', method.POST, toJsonStr(request)); },
  updateAddonGroup(request) { return handleRequest('addonGroups/update', method.POST, toJsonStr(request)); },
  deleteAddonGroup(addonGroupId) { return handleRequest(`addonGroups/delete/${addonGroupId}`, method.DELETE); },
  getAddonGroupList() { return handleRequest('addonGroups/list', method.GET, null); },
  uploadAddonGroupImg(addonGroupId, request) { return handleImgUploadRequest(`addonGroups/img/update/${addonGroupId}`, method.POST, request); },
  deleteAddonGroupImage(addonGroupId) { return handleRequest(`addonGroups/img/delete/${addonGroupId}`, method.DELETE); },

  // Floor Details
  createFloor(request) { return handleRequest('floor/create', method.POST, toJsonStr(request)); },
  getFloorList() { return handleRequest('floor/list', method.GET, null); },
  getGuestModeFloorList() { return handleRequest('guestMode/floor/list', method.GET, null); },

  // Table Details
  createTable(request) { return handleRequest('table/create', method.POST, toJsonStr(request)); },
  getTableNameList(floorId) { return handleRequest(`floor/table/list/${floorId}`, method.GET, null); },
  getGuestModeTableNameList(floorId) { return handleRequest(`guestMode/floor/table/list/${floorId}`, method.GET, null); },
  updateTableLocation(request) { return handleRequest('table/location/update', method.POST, toJsonStr(request)); },
  updateInActiveTable(request) { return handleRequest('table/active/update', method.POST, toJsonStr(request)); },
  tableFloorUpdate(request) { return handleRequest('table/update', method.PUT, toJsonStr(request)); },
  getPaidTableList() { return handleRequest('fetch/paid/table', method.GET, null); },
  updateTableRelease(request) { return handleRequest('release/paid/table', method.PUT, toJsonStr(request)); },
  getActiveInActiveTable(floorId) { return handleRequest(`floor/tables/all/${floorId}`, method.GET, null); },

  // Inventory
  getInventoryList() { return handleRequest('inventory/list', method.GET, null); },
  getSearchInventoryList(searchITem) { return handleRequest(`inventory/list/search/${searchITem}`, method.GET, null); },
  createInventoryItem(request) { return handleRequest('inventory/create', method.POST, toJsonStr(request)); },
  getInventoryViewDetails(inventoryId) { return handleRequest(`inventory/view/${inventoryId}`, method.GET, null); },
  updateInventoryItem(request) { return handleRequest('inventory/update', method.POST, toJsonStr(request)); },
  bulkInventoryCreate(request) { return handleRequest('inventory/bulk/create', method.POST, toJsonStr(request)); },
  deleteInventoryViewDetails(inventoryId) { return handleRequest(`inventory/delete/${inventoryId}`, method.DELETE, null); },

  // Offer
  createOffer(request) { return handleRequest('offer/create', method.POST, toJsonStr(request)); },
  updateOffer(request) { return handleRequest('offer/update', method.PUT, toJsonStr(request)); },
  deleteOffer(offerId) { return handleRequest(`offer/delete/${offerId}`, method.DELETE, null); },
  getOfferList() { return handleRequest('menu/offer/list', method.GET, null); },
  getOfferViewDetails(offerId) { return handleRequest(`offer/view/${offerId}`, method.GET, null); },
  getOfferAvailabilityStatuses() { return handleRequest('offer/availability/status', method.GET, null); },
  // Rewards
  getCurrentOfferList() { return handleRequest(`current/offer/list/${productToken}`, method.GET, null); },
  getCustomerRewards(phoneNumber) { return handleRequest(`customer/reward/${phoneNumber}`, method.GET, null); },
  getCustomerModeRewards(phoneNumber) { return handleRequest(`customer/reward/customerMode/${phoneNumber}/${productToken}`, method.GET, null); },
  createReward(request) { return handleRequest('customer/reward/create', method.POST, toJsonStr(request)); },
  updateReward(request) { return handleRequest('customer/reward/update', method.POST, toJsonStr(request)); },

  // Location Config
  locationCreate(request) { return handleRequest('location/create', method.POST, toJsonStr(request)); },
  locationUpdate(request) { return handleRequest('location/update', method.POST, toJsonStr(request)); },
  logoImageUpload(locationId, request) { return handleImgUploadRequest(`location/logo/update/${locationId}`, method.POST, request); },
  deleteLogoImage(locationId) { return handleRequest(`location/logo/delete/${locationId}`, method.DELETE); },
  getDelayETAStatusList() { return handleRequest('om/deliveryDelay/list', method.GET, null); },

  // Employee Server Mapping
  getActiveEmployeeList() { return handleRequest('employee/active', method.GET, null); },
  getActiveTableList() { return handleRequest('table/active/list', method.GET, null); },
  allocateTableEmployee(request) { return handleRequest('multi/table/server/assign', method.POST, toJsonStr(request)); },
  moveEmployeeTable(request) { return handleRequest('server/update', method.POST, toJsonStr(request)); },

  // PrintConfig
  createPrintConfig(request) { return handleRequest('print/config/create', method.POST, toJsonStr(request)); },
  updatePrintConfig(request) { return handleRequest('print/config/update', method.POST, toJsonStr(request)); },
  printConfigImageUpload(configId, request) { return handleImgUploadRequest(`print/config/img/${configId}`, method.PUT, request); },

  // Sort Order
  updateCategorySortOrder(request) { return handleRequest('category/sort/update', method.PUT, toJsonStr(request)); },
  updateMenuTypeSortOrder(request) { return handleRequest('menu-type/sort/update', method.PUT, toJsonStr(request)); },
  updateMenuSortOrder(request) { return handleRequest('menu/sort/update', method.PUT, toJsonStr(request)); },
  getMenuSortOrderSelect() { return handleRequest('menu/sort/order/select', method.GET, null); },

  //  General Customization
  createGeneralCustomize(request) { return handleRequest('gen/cust/create', method.POST, toJsonStr(request)); },
  updateGeneralCustomize(request) { return handleRequest('gen/cust/update', method.POST, toJsonStr(request)); },
  getGeneralCustomizeList(type) { return handleRequest(`gen/cust/select/${type}`, method.GET, null); },
  deleteGeneralCustomize(ordGenCustDetId) { return handleRequest(`gen/cust/delete/${ordGenCustDetId}`, method.DELETE, null); },

  // Front End Error post to server
  sendError(request) { return handleRequest('frontEndError/create', method.POST, toJsonStr(request)); },

  // Asset Management
  getRegisteredDevicesList() { return handleRequest('deviceList', method.GET, null); },
  deviceStateUpdate(request) { return handleRequest('deviceStatusUpdate', method.PUT, toJsonStr(request)); },
  updateAsset(request) { return handleRequest('asset/update', method.POST, toJsonStr(request)); },
  deleteDevice(assetId) { return handleRequest(`asset/delete/${assetId}`, method.DELETE, null); },

  // Terminals List
  getAvialabelTerminalsList() { return handleRequest('availableTerminalList', method.GET, null); },
  createDeviceMapWithTerminal(request) { return handleRequest('paymentDeviceMappingCreate', method.POST, toJsonStr(request)); },
  updateDeviceMapWithTerminal(request) { return handleRequest('paymentDeviceMappingUpdate', method.PUT, toJsonStr(request)); },

  // OOS Config
  updateOosDetails(request) { return handleRequest('oosConfig/update', method.PUT, toJsonStr(request)); },
  getOOSDetails() { return handleRequest('oosConfig/select', method.GET, null); },
  uploadPromotionImage(request, oosId) { return handleImgUploadRequest(`promotion/image/create/${oosId}`, method.POST, request); },
  deletePromotionImage(promotionImageId) { return handleRequest(`promotion/image/delete/${promotionImageId}`, method.DELETE); },
  bannerImageUpload(request, oosId) { return handleImgUploadRequest(`oosConfig/img/update/${oosId}`, method.POST, request); },

  // Report
  getCategoryReports(categoryId, startDate, endDate) { return handleRequest(`category/report/select/${categoryId}/${startDate}/${endDate}`, method.GET, null); },
  getVoidTrendReports(startDate, endDate) { return handleRequest(`report/select/voidTrends/${startDate}/${endDate}`, method.GET, null); },
  getGuestTrendReports(guestId, startDate, endDate) { return handleRequest(`guest/report/select/${guestId}/${startDate}/${endDate}`, method.GET, null); },
  getInventoryTrendUsageReports(startDate, endDate) { return handleRequest(`inventory/report/history/select/${startDate}/${endDate}`, method.GET, null); },
  getInventoryTrendInHandReports() { return handleRequest('inventory/report/inHand/select', method.GET, null); },
  getInventoryTrendOrderingReports() { return handleRequest('inventory/report/ordering/select', method.GET, null); },
  getEmployeeSalesReports(empId, startDate, endDate) { return handleRequest(`report/employee/select/${empId}/${startDate}/${endDate}`, method.GET, null); },

  // Clock in Clock out Report

  getClockInClockOutDetails(empId, startDate, endDate, isFiltered, workType) {
    return handleRequest(`report/clockIn/ClockOut/select/${empId}/${startDate}/${endDate}/${isFiltered}?workType=${workType}`, method.GET, null);
  },

  // Close Day
  createCloseDay() { return handleRequest('closeDay/create', method.POST, null); },
  getValidateCloseDay() { return handleRequest('validate/closeDay', method.POST, null); },

  // Scheduler
  updateRunNow(request) { return handleRequest('scheduler/run/now', method.PUT, toJsonStr(request)); },
  cancelScheduler(request) { return handleRequest('scheduler/void', method.PUT, toJsonStr(request)); },
  getSchedulerList() { return handleRequest('scheduler/stk/select', method.GET, null); },
  adjustSchedulerTime(request) { return handleRequest('scheduler/runtime/update', method.PUT, toJsonStr(request)); },

  // TIme Slot
  createTimeSlot(request) { return handleRequest('location/timeSlots/create', method.POST, toJsonStr(request)); },
  updateTimeSlot(request) { return handleRequest('location/timeSlots/update', method.PUT, toJsonStr(request)); },
  getTimeSlot() { return handleRequest('location/timeSlots', method.GET, null); },
  deleteTimeSlot(request) { return handleRequest('location/timeSlots/delete', method.DELETE, toJsonStr(request)); },

};

export default AdminServices;
