import AdminServices from '../modules/services/AdminServices';

const postError = async (errorType, errMessage) => {
  if (errorType === 'error' || errorType === 'fatal') {
    let cnvStr;
    if (errMessage && errMessage.stack) {
      cnvStr = `MESSAGE: ${errMessage.stack}`;
    } else {
      const val = JSON.stringify(errMessage);
      cnvStr = `MESSAGE:${val}`;
    }
    // TODO: Log catch error name also::
    // add  this 'errMessage.name' to cnvStr to get ErrorType Name
    const req = { message: cnvStr };
    const result = await AdminServices.sendError(req);
    return result;
  }
  return null;
};

const logger = {
  info: (...x) => console.log(...x),
  debug: (...x) =>console.log(...x),
  error: (...x) => {
    postError('error', ...x);
  },
  fatal: (...x) => {
    postError('fatal', ...x);
  },
};

export default logger;
