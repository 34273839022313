import React from 'react';
import logger from '../common/logger';
import KuberRoute from '../routes/KuberRoute';

const KuberLayout = () => {
  logger.info('*** KuberLayout ***');
  return (
    <>
      <KuberRoute />
    </>
  );
};

export default KuberLayout;
