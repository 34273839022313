export function getPayload(categoryId, categoryDetails, printerArray,
  categoryTimeSlotList, categoryTimeTableList, categoryList) {
  const payload = setCategoryRequest(categoryId, categoryDetails,
    printerArray, categoryTimeSlotList,
    categoryTimeTableList, categoryList);

  return payload;
}

export function setCategoryRequest(categoryId, categoryDetails,
  printerArray, categoryTimeSlotList,
  categoryTimeTableList, categoryList) {
  let request = null;
  const { virtualPrinterName, virtualPrinterCategories, virtualPrinterId } = getPrinterData(printerArray);
  const dependencyCategory = getDependencyCategory(categoryList);
  if (categoryId === 0) {
    request = {
      categoryDetails: {
        categoryId,
        categoryName: categoryDetails.categoryName,
        categoryAdditionalTax: categoryDetails.categoryAdditionalTax === '' ? 0 : categoryDetails.categoryAdditionalTax,
        sortOrder: 111,
        isCategoryOutOfStock: (categoryDetails.isCategoryOutOfStock === true
          || categoryDetails.isCategoryOutOfStock === 1) ? 1 : 0,
        categoryTimeSlot: getTimeSlot(categoryTimeSlotList),
        categoryTimeTable: getTimeTable(categoryTimeTableList),
        dependentCategories: dependencyCategory,
        isDiscountApplicable: categoryDetails.isDiscountApplicable,
        status: (categoryDetails.status === true || categoryDetails.status === 1) ? 1 : 0,

      },
      virtualPrinterDetails: {
        virtualPrinterName,
        virtualPrinterType: virtualPrinterCategories,
        virtualPrinterId,
      },
    };
  } else {
    request = {
      categoryDetails: {
        categoryId,
        categoryName: categoryDetails.categoryName,
        categoryAdditionalTax: categoryDetails.categoryAdditionalTax === '' ? 0 : categoryDetails.categoryAdditionalTax,
        sortOrder: 111,
        isCategoryOutOfStock: (categoryDetails.isCategoryOutOfStock === true
          || categoryDetails.isCategoryOutOfStock === 1) ? 1 : 0,
        categoryTimeSlot: getTimeSlot(categoryTimeSlotList),
        categoryTimeTable: getTimeTable(categoryTimeTableList),
        dependentCategories: dependencyCategory,
        isDiscountApplicable: categoryDetails.isDiscountApplicable,
        status: (categoryDetails.status === true || categoryDetails.status === 1) ? 1 : 0,

      },
    };
  }

  return request;
}
export function getDependencyCategory(categoryList) {
  const dependencyCategory = [];
  if (categoryList !== null && categoryList.length > 0) {
    categoryList.map((item) => {
      if (item.checked === true) {
        dependencyCategory.push(item.categoryId);
      }
      return item;
    });
  }
  return dependencyCategory;
}

export function getTimeSlot(list) {
  const items = [];
  if (list && list.length > 0) {
    list.map((item) => items.push({
      slotName: item.slotName,
      slotStartTime: item.slotStartTime,
      slotStopTime: item.slotStopTime,
      timeSlotId: item.slotId,
    }));
  }

  return items;
}
export function getTimeTable(list) {
  const items = [];
  if (list && list.length > 0) {
    list.map((item) => items.push({
      timeTableRepeatType: item.timeTableRepeatType,
      timeTableRecurring: item.timeTableRecurring,
    }));
  }
  return items;
}
export function getPrinterData(printerArray) {
  const virtualPrinterName = printerArray.length > 0 ? printerArray[0].virtualPrinterName : '';
  const virtualPrinterCategories = printerArray.length > 0 ? printerArray[0].virtualPrinterCategories : '';
  const virtualPrinterId = printerArray.length > 0 ? printerArray[0].virtualPrinterId : '';
  return { virtualPrinterName, virtualPrinterCategories, virtualPrinterId };
}
