/**
 * @author Manimalar
 * @description React useContext is used to manage the Application context. Ex: Location Config
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserServices from '../modules/services/UserServices';
import log from './logger';
import LocalStorageHandler from '../modules/auth/LocalStorageHandler';
import ServerNotResponding from './server-not-responding/ServerNotResponding';
import Toast from './Toast';
import AdminServices from '../modules/services/AdminServices';

export const appContext = React.createContext({}); // Created context

export const Provider = (props) => {
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [locationDetails, setLocationDetails] = useState(null);
  const [locationTimeSlot, setLocationTimeSlot] = useState([]);
  const [metaDataList, setMetaDataList] = useState({});
  const [profileDetails, setProfile] = useState(null);
  const [isDataEditing, setIsDataEditing] = useState(false);
  // Sort order
  const [isSortOrder, setIsSortOrder] = useState(false);
  const [unSaveSortOrderReq, setUnSaveSortOrderReq] = useState(null);
  const [sortOrderType, setSortOrderType] = useState('');
  const [taxDetails, setTaxDetails] = useState(null);
  const [isFireOrder, setIsFireOrder] = useState(false);

  const [toastData, setToastData] = useState(null);
  const [offerList, setOfferList] = useState([]);
  const [isPayment, setIsPayment] = useState(false);
  const [currentOfferList, setCurrentOfferList] = useState([]);
  const [stkRemoveItem, setStkRemoveItem] = useState(null);
  const [stkRemoveAddOn, setStkRemoveAddOn] = useState(null);

  const [delayStatusList, setDelayStatusList] = useState([]);

  // Transaction

  const [paidTransactionList, setPaidTransactionList] = useState([]);
  const [crDetails, setCRDetails] = useState([]);

  const [decodedUserToken, setDecodedUserToken] = useState('');
  const [lastCloseDayDetails, setLastCloseDetails] = useState(null);
  const [validateCloseDayList, setValidateCloseDayList] = useState([]);
  const [lcDelayETA, setLCDelayETA] = useState('');

  // iFrame Url

  const [iFrameUrl, setIframeUrl] = useState('');

  const hideAlert = () => setErrorText('');

  const getLocationDetails = async () => {
    log.info('*** getLocationDetails ***');
    try {
      const pToken = LocalStorageHandler.getProductToken();
      if (pToken !== null) {
        const response = await UserServices.getLocationDetails(pToken);
        const result = await response.json();
        if (result.status === 200) {
          if (result.data || result.data !== '') setLocationDetails(result.data);
        } else {
          setToastData(result);
        }
      }
    } catch (err) {
      setErrorText(t('genericTerms.serverNotResponding'));
    }
  };

  const refreshLocationDetails = async () => {
    await getLocationDetails();
  };

  const getCurrentOfferList = async () => {
    try {
      log.info('*** getCurrentOfferList ***');
      const pToken = LocalStorageHandler.getProductToken();
      if (pToken !== null) {
        const response = await AdminServices.getCurrentOfferList();
        const result = await response.json();
        AdminServices.authUser(result.errors, props);
        if (result.status === 200) {
          setCurrentOfferList(result.data);
        } else {
          setToastData(result);
        }
      }
    } catch (err) {
      setErrorText(t('genericTerms.serverNotResponding'));
    }
  };

  useEffect(() => {
    log.info('*** appUseContext ***');
    getLocationDetails();
    getCurrentOfferList();
  }, []);

  return (
    <>
      <Toast toastData={toastData} />
      {errorText !== '' && <ServerNotResponding errorText={errorText} hideAlert={hideAlert} />}
      <appContext.Provider
        value={{
          locationDetails, // Restaurants  location Details
          profileDetails, // Current login profile details,
          offerList,
          metaDataList,
          isDataEditing,
          isSortOrder,
          unSaveSortOrderReq,
          taxDetails,
          isFireOrder,
          sortOrderType,
          isPayment,
          currentOfferList,
          stkRemoveItem,
          stkRemoveAddOn,
          decodedUserToken,
          paidTransactionList,
          crDetails,
          lastCloseDayDetails,
          validateCloseDayList,
          lcDelayETA,
          delayStatusList,
          iFrameUrl,
          isDarkMode,
          locationTimeSlot,
          refreshLocationDetails,
          setProfileValues: (profileVal) => setProfile(profileVal), // Data passed parent to child
          setMetaData: (token) => setMetaDataList(token),
          updateEditingData: (boolean) => setIsDataEditing(boolean),
          updateSortOrder: (boolean) => setIsSortOrder(boolean),
          updateSortOrderType: (type) => setSortOrderType(type),
          setOfferValues: (offer) => setOfferList(offer),
          updateUnSaveSortOrderReq: (payload) => setUnSaveSortOrderReq(payload),
          setTaxData: (value) => setTaxDetails(value),
          updateLocationDetails: (value) => setLocationDetails(value),
          updateFireOrder: (boolean) => setIsFireOrder(boolean),
          updateIsPayment: (boolean) => setIsPayment(boolean),
          updateStkRemoveItem: (removeList) => setStkRemoveItem(removeList),
          updateStkRemoveAddOn: (addOnList) => setStkRemoveAddOn(addOnList),
          setDecodedUsedTokenValue: (token) => setDecodedUserToken(token),
          setPaidTransValue: (list) => setPaidTransactionList(list),
          setCrData: (value) => setCRDetails(value),
          setCloseDayUserDetails: (details) => setLastCloseDetails(details),
          setCloseDayDetails: (list) => setValidateCloseDayList(list),
          updateLCDelayETA: (value) => setLCDelayETA(value),
          setDelayETAList: (list) => setDelayStatusList(list),
          setIframeLink: (url) => setIframeUrl(url),
          updateDarkMode: (boolean) => setIsDarkMode(boolean),
          updateCurrentOfferList: (list) => setCurrentOfferList(list),
          updateTimeSlot: (list) => setLocationTimeSlot(list),
        }}
      >
        {props.children}
      </appContext.Provider>
    </>
  );
};
