import AdminServices from '../../../services/AdminServices';
import UserServices from '../../../services/UserServices';
import log from '../../../../common/logger';

const ServiceHandler = {
  async getLocationDetails(props) {
    try {
      const response = await UserServices.getLocationDetails();
      const result = await response.json();
      UserServices.authUser(result.errors, props);
      return result;
    } catch (err) {
      log.error(err);
    }
  },
  async updateLocationDetails(REQ, props) {
    const response = await AdminServices.locationUpdate(REQ);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async createLocationDetails(REQ, props) {
    const response = await AdminServices.locationCreate(REQ);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async logoUpload(locationId, formData, props) {
    const response = await AdminServices.logoImageUpload(locationId, formData);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async deleteLogo(locationId, props) {
    const response = await AdminServices.deleteLogoImage(locationId);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },

  async createTimeSlot(props, REQ) {
    const response = await AdminServices.createTimeSlot(REQ);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async updateTimeSlot(props, REQ) {
    const response = await AdminServices.updateTimeSlot(REQ);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async deleteTimeSlotDetails(props, REQ) {
    const response = await AdminServices.deleteTimeSlot(REQ);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async getTimeSlotList(props) {
    const response = await AdminServices.getTimeSlot();
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
};
export default ServiceHandler;
