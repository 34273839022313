import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import TableComponent from '../../inventory/table'

const TimeSlotDetails = ({ timeSlotToggleForm, timeSlotColumns, timeSlot }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="my-2">
        <Col md={11}>
          <h4 className="mt-3">{t('weekDays.slotDetails')}</h4>

        </Col>
        <Col md={1} className="createMenyType createCategoryList">
          <span className="addNewBtnSec">
            <Button
              type="button"
              className="btn  btn-primary-color btn-primary-color1"
              onClick={() => timeSlotToggleForm(-1)}
            >
              <i className="fa fa-plus" />
              {' '}
              {`${t('genericTerms.new')}`}
            </Button>
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <TableComponent columns={timeSlotColumns} data={timeSlot} />
        </Col>
      </Row>
    </div>
  );
};

export default TimeSlotDetails;
