import { sort } from '../../../../common/utils/string-utils';

export const setVPValue = (virtualPrinterList, item) => {
  const virtualList = virtualPrinterList;
  const updatedList = virtualList.map((list) => {
    if (list.virtualPrinterName === item.virtualPrinterName) {
      // eslint-disable-next-line no-param-reassign
      list.checked = true;
    }
    return list;
  });

  return updatedList;
};

export const setOnloadVPValue = (list) => {
  const listOfItems = list;
  const updateData = listOfItems.map((item) => {
    let data = item;
    data = { ...data, checked: false };
    return data;
  });
  const sortData = updateData.length > 0 ? sort(updateData, (a, b) => a.virtualPrinterName.localeCompare(b.virtualPrinterName)) : updateData;

  return sortData;
};

export const getCheckedVPName = (vpList) => {
  let printerName = '';
  vpList.map((item) => {
    if (item.checked === true) printerName = item.virtualPrinterName;
    return item;
  });
  return printerName;
};
export const setOnloadCategory = (list) => {
  const listOfItems = list;
  const updateData = listOfItems.map((item) => {
    let data = item;
    data = { ...data, checked: false };
    return data;
  });
  const sortData = updateData.length > 0 ? sort(updateData, (a, b) => a.categoryName.localeCompare(b.categoryName)) : updateData;

  return (sortData);
};

export const setStatusMessage = (status, message) => ({ status, message });
export const concatValue = (list, item) => list.concat(item);

export const getPrinterCategories = (virtualPrinterList, printerName) => {
  const list = virtualPrinterList;
  let printerArray = [];
  if (list.length > 0) {
    printerArray = list.filter((item) => {
      const data = item;
      if (item.virtualPrinterName === printerName) {
        const parsedArray = item.virtualPrinterCategories;
        // JSON.parse(item.virtualPrinterCategories);
        data.virtualPrinterCategories = parsedArray;
        // [...parsedArray];
      }
      return data.virtualPrinterName === printerName;
    });
  }

  return printerArray;
};

export const setDependencyCategoryList = (el, item, dependencyCategory) => {
  const list = dependencyCategory;
  if (el.target.checked === true) {
    list.push(item.categoryId);
  } else {
    list.forEach((data, i) => {
      if (data.categoryId === item.categoryId) {
        list.splice(i, 1);
      }
    });
  }
  return list;
};

export const getSelectedCategoryName = (categoryList) => {
  const list = categoryList;
  let selectedCategoryNames = '';

  if (list.length > 0) {
    list.forEach((item) => {
      if (item.checked === true) {
        selectedCategoryNames += `${item.categoryName},`;
      }
    });
  }
  if (selectedCategoryNames !== '') selectedCategoryNames = selectedCategoryNames.slice(0, -1);
  return selectedCategoryNames;
};

export const setSelectedCategory = (dependencyCategory, categoryList) => {
  const list = categoryList;
  if (dependencyCategory.length > 0) {
    dependencyCategory.map((categoryId) => {
      let index = -1;
      list.map((item, i) => {
        if (item.categoryId === categoryId) {
          index = i;
          return index;
        }
        return item;
      });
      if (index > -1) list[index].checked = true;
      return categoryId;
    });
    return list;
  }
};

export const setSelectedWeekDays = (categoryTimeTable) => {
  const values = categoryTimeTable;
  if (values?.length > 0) {
    values.map((item) => {
      const data = item;
      data.days = item.timeTableRepeatType === 'Daily' ? [] : item.timeTableRecurring;
      if (item?.timeTableRecurring.length > 0) {
        let name = '';
        item.timeTableRecurring.map((day) => {
          if (day === 0) name = 'Sun';
          if (day === 1) name = name !== '' ? name.concat(', Mon') : name.concat(' Mon');
          if (day === 2) name = name !== '' ? name.concat(', Tue') : name.concat(' Tue');
          if (day === 3) name = name !== '' ? name.concat(', Wed') : name.concat(' Wed');
          if (day === 4) name = name !== '' ? name.concat(', Thu') : name.concat(' Thu');
          if (day === 5) name = name !== '' ? name.concat(', Fri') : name.concat(' Fri');
          if (day === 6) name = name !== '' ? name.concat(', Sat') : name.concat(' Sat');

          data.days = name;
          return data.days;
        });
      }
      return item;
    });
  }

  return values;
};
