import React from 'react';
import {
  Row, Col, Input, Label, CustomInput,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Toggle from '../../../../common/toggle';

const EditOtherDetails = ({
  locationDetails, handleFormChange, handleToggleValue, inventoryManagementToggleHandler,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="my-2">
        <Col lg={6} md={12} sm={12}>
          <h4>
            {`${t('genericTerms.other')} ${t(
              'genericTerms.details',
            )}`}
          </h4>
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="cuisineType" className="required text-muted fs-12px">
            {`${t('genericTerms.cuisine')} ${t(
              'genericTerms.type',
            )}`}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            //   ref={(el) => (this.cuisineType = el)}
            type="text"
            className="form-control form-control1"
            placeholder="Cuisine Type "
            id="cuisineType "
            name="cuisineType"
            defaultValue={locationDetails?.cuisineType}
          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="weightUnits" className="required text-muted fs-12px">
            {`${t('genericTerms.weight')} ${t(
              'genericTerms.units',
            )}`}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            // ref={(el) => (this.weightUnits = el)}
            type="text"
            className="form-control form-control1"
            placeholder="Weight Units "
            id="weightUnits "
            name="weightUnits"
            defaultValue={locationDetails?.weightUnits}
          />

        </Col>

        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="maxCRAmount" className="required text-muted fs-12px">
            {t('locationConfig.maxCrAmount')}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            // ref={(el) => (this.maxCRAmount = el)}
            type="number"
            className="form-control form-control1"
            placeholder="Max CR Amount "
            id="maxCRAmount "
            name="maxCRAmount"
            defaultValue={locationDetails?.maxCRAmount}
          />

        </Col>

        <Col lg={3} md={4} sm={6}>

          <Label
            htmlFor="rewardsPointsValue"
            className="required text-muted fs-12px"
          >
            {`${t('reward.rewardPoint')} ${t(
              'genericTerms.value',
            )}`}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            // ref={(el) => (this.rewardsPointsValue = el)}
            type="number"
            className="form-control form-control1"
            placeholder="Rewards Points Value"
            id="rewardsPointsValue "
            name="rewardsPointsValue"
            defaultValue={locationDetails?.rewardsPointsValue}
          />

        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>

          <Label
            htmlFor="deliveryCharges"
            className="required text-muted fs-12px"
          >
            {t('locationConfig.deliveryCharge')}
          </Label>
          <Input
            type="number"
            className="form-control form-control1"
            placeholder="Delivery Charge "
            id="deliveryCharges "
            defaultValue={locationDetails?.deliveryCharges}
            onChange={(event) => handleFormChange(event)}
            name="deliveryCharges"
          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label
            htmlFor="paymentVpLinkValidityTime"
            className="required text-muted fs-12px"
          >
            {t('locationConfig.pamentLinkValidity')}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            type="number"
            max="2"
            className="form-control form-control1"
            placeholder="payment VpLink Validity Time"
            id="paymentVpLinkValidityTime "
            name="paymentVpLinkValidityTime"
            defaultValue={locationDetails?.paymentVpLinkValidityTime}
          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="expDays" className="required text-muted fs-12px">
            {t('locationConfig.rewardExpDays')}
          </Label>
          <Input
            type="number"
            className="form-control form-control1"
            //   ref={(el) => (this.expDays = el)}
            placeholder="Reward Expiry Days"
            id="expDays"
            name="expDays"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.expDays}
          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label
            htmlFor="rwdMinPoints"
            className="required text-muted fs-12px"
          >
            {t('locationConfig.rewardMinPoints')}
          </Label>
          <Input
            type="number"
            className="form-control form-control1"
            //   ref={(el) => (this.rwdMinPoints = el)}
            placeholder="Minimum Reward Points"
            id="rwdMinPoints"
            name="rwdMinPoints"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.rwdMinPoints}
          />

        </Col>

      </Row>
      <Row>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="localhostIp" className="required text-muted fs-12px">
            {t('locationConfig.localIp')}
          </Label>
          <Input
            type="text"
            className="form-control form-control1"
            //   ref={(el) => (this.localhostIp = el)}
            placeholder="Localhost Ip"
            id="localhostIp"
            name="localhostIp"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.localhostIp}

          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label
            htmlFor="localhostPort"
            className="required text-muted fs-12px"
          >
            {t('locationConfig.localPortNumber')}
          </Label>
          <Input
            type="number"
            className="form-control form-control1"
            //   ref={(el) => (this.localhostPort = el)}
            placeholder="Localhost Port Number"
            id="localhostPort"
            name="localhostPort"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.localhostPort}
          />

        </Col>
        <Col lg={3} md={4} sm={6}>

          <Label htmlFor="awsUrl" className="required text-muted fs-12px">
            {t('locationConfig.remoteIp')}
          </Label>
          <Input
            type="text"
            className="form-control form-control1"
            //   ref={(el) => (this.awsUrl = el)}
            placeholder="Remote Host Ip"
            id="awsUrl"
            name="awsUrl"
            onChange={(event) => handleFormChange(event)}
            defaultValue={locationDetails?.awsUrl}
          />

        </Col>

        <Col lg={3} md={4} sm={6}>
          <div>
            <Label htmlFor="Status" className="required text-muted fs-12px">
              {t('locationConfig.localOrRemote')}
            </Label>
            <Toggle
              id="deviceStatus"
              name="deviceStatus"
              title="Status"
              handleValue={handleToggleValue}
              checked={locationDetails.status}
            />
          </div>

        </Col>
      </Row>
      <Row>
        <Col lg={3} md={4} sm={6}>
          <div>
            <Label className="text-muted fs-12px" htmlFor="inventoryManagementStatus">
              {t('locationConfig.inventoryMmtStatus')}
            </Label>
            <Toggle
              id="inventoryManagementStatus"
              name="inventoryManagementStatus"
              title="inventoryStatus"
              handleValue={inventoryManagementToggleHandler}
              checked={locationDetails.inventoryManagementActiveStatus}
            />
          </div>

        </Col>
        <Col lg={4} md={4} sm={6}>
          <CustomInput
            type="checkbox"
            defaultChecked={locationDetails?.isConfirmAmount}
                // ref={(el) => (this.isConfirmAmount = el)}
            id="isConfirmAmount"
            name="isConfirmAmount"
            label={t('genericTerms.confirmAmount')}
            onChange={(event) => handleFormChange(event)}
            inline
          />

        </Col>
        <Col lg={4} md={4} sm={6}>
          <CustomInput
            type="checkbox"
            defaultChecked={locationDetails?.isZeroTaxForOosTogo}
                // ref={(el) => (this.isConfirmAmount = el)}
            id="isZeroTaxForOosTogo"
            name="isZeroTaxForOosTogo"
            label={t('genericTerms.zeroTaxForOosTogo')}
            onChange={(event) => handleFormChange(event)}
            inline
          />

        </Col>
      </Row>
      <hr />

    </>
  );
};

export default EditOtherDetails;
