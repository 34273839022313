import ApiConfig from '../../config/ApiConfig';
import LocalStorageHandler from '../auth/LocalStorageHandler';

const method = {
  POST: 'post', GET: 'get', PUT: 'put', DELETE: 'delete',
};

const productToken = LocalStorageHandler.getProductToken();
const toJsonStr = (val) => JSON.stringify(val);
const httpHeaders = { Accept: 'application/json', 'Content-Type': 'application/json' };
const handleRequest = (apiKey, methodType, bodyType) => {
  const getWithCredentialHeader = {
    'Content-Type': 'application/json',
    Authorization: LocalStorageHandler.getUserToken(),
  };

  return fetch(ApiConfig.API_BASE + apiKey, {
    method: methodType,
    headers: getWithCredentialHeader,
    body: bodyType,
  });
};
const UserServices = {
  // If invalid User redirect to login
  authUser(errors, props) {
    if (errors !== null && errors !== undefined && errors[0].msg === 'noauth') LocalStorageHandler.clearCache(() => props.history.push('/login'));
  },

  userLogin(req) {
    const requestJson = JSON.stringify(req);
    return fetch(`${ApiConfig.API_BASE}login`, {
      method: 'post',
      headers: httpHeaders,
      body: requestJson,
    });
  },
  productLogin(req) {
    const requestJson = JSON.stringify(req);
    return fetch(`${ApiConfig.API_BASE}product/login`, {
      method: 'post',
      headers: httpHeaders,
      body: requestJson,
    });
  },
  loginByDeviceId({ deviceId }) { return handleRequest(`deviceByIdForLogin/${deviceId}`, method.GET, null); },
  registerDevice(payload) { return handleRequest('register/device', method.POST, toJsonStr(payload)); },
  getLocationDetails(pToken) { return handleRequest(`location/details/${pToken || productToken}`, method.GET, null); },
  getProfileDetails() { return handleRequest('user/profile', method.GET, null); },
  getCCMerchantDetails() { return handleRequest('inquire/merchant', method.GET, null); },
  getMetaData() { return handleRequest('om/metaData', method.GET, null); },
  getHomePageList() { return handleRequest('home', method.GET, null); },
  tableAllocated(request) { return handleRequest('allocate/table', method.POST, toJsonStr(request)); },
  updateDelayEtA(request) { return handleRequest('om/deliveryDelay/update', method.POST, toJsonStr(request)); },
  // OM Module
  adminManagerVerification(passcode) { return handleRequest(`validation/${passcode}`, method.GET, null); },
  splitOrderCreate(request) { return handleRequest('split/create', method.POST, toJsonStr(request)); },
  getOnLoadDataList(tableId) { return handleRequest(`om/${tableId}`, method.GET, null); },
  getBasedOnCategoryMenuList(request) { return handleRequest('menu/active/list', method.POST, toJsonStr(request)); },
  getMenuTypeBasedCategoryList(categoryIdList) { return handleRequest('om/menu/list', method.POST, toJsonStr(categoryIdList)); },
  removeGuest(req) { return handleRequest('guest/update', method.POST, toJsonStr(req)); },
  splitOrderRemoveGuest(request) { return handleRequest('remove/guest', method.POST, toJsonStr(request)); },
  cartCreateUpdate(request) { return handleRequest('om/cart', method.POST, toJsonStr(request)); },
  updateOrderSendToKitchen(request) { return handleRequest('update/sent/to/kitchen', method.PUT, toJsonStr(request)); },
  orderClosed(request) { return handleRequest('close/order', method.POST, toJsonStr(request)); },
  tableRelease(request) { return handleRequest('table/release', method.POST, toJsonStr(request)); },
  getTableAvailableList(tableId) { return handleRequest(`table/active/list/id/${tableId}`, method.GET, null); },
  updateMoveTable(request) { return handleRequest('move/table', method.PUT, toJsonStr(request)); },
  updateGratuity(request) { return handleRequest('update/gratuity', method.PUT, toJsonStr(request)); },
  updateGuestOrderComments(request) { return handleRequest('om/order/update/comments', method.POST, toJsonStr(request)); },
  updateGuestTaxFreeComments(request) { return handleRequest('om/order/taxFree/update', method.POST, toJsonStr(request)); },

  // Quick order
  createQuickOrder() { return handleRequest('quick/order/create', method.POST, null); },
  getQuickOrderOpenList() { return handleRequest('quick/order/open/list', method.GET, null); },
  quickOrderClosed(request) { return handleRequest('quick/order/void', method.POST, toJsonStr(request)); },
  getQuickOrderOnLoadDataList(mmtId) { return handleRequest(`quick/order/om/onload/${mmtId}`, method.GET, null); },
  quickOrderCartCreateUpdate(request) { return handleRequest('quick/order/cart/create', method.POST, toJsonStr(request)); },

  // Transaction
  getTranscationOrderIds() { return handleRequest('fetchOrderIds', method.GET, null); },
  getTransactionList(searchTransaction) { return handleRequest(`transaction/search/${searchTransaction}`, method.GET, null); },
  getTransactionData(orderType, startDate, endDate) { return handleRequest(`transaction/${orderType}/${startDate}/${endDate}`, method.GET, null); },
  updatePaidTransaction(request) { return handleRequest('transaction/paid/update', method.POST, toJsonStr(request)); },
  // Customer
  createGuest(request) { return handleRequest('guest/create', method.POST, toJsonStr(request)); },
  getCustomerList() { return handleRequest('guest/select', method.GET, null); },
  getCustomerDetails(phoneNumber) { return handleRequest(`customer/phoneNumber/${phoneNumber}`, method.GET, null); },
  customerPhoneNumberAlreadyExistsCount(phoneNo) { return handleRequest(`cust/phoneNumber/count/${phoneNo}`, method.GET, null); },
  createnewCustomer(request) { return handleRequest('create/customer/new', method.POST, toJsonStr(request)); },
  getTogoOrderList() { return handleRequest('togo/list', method.GET, null); },
  togoOrderStatus(request) { return handleRequest('togo/order/update ', method.POST, toJsonStr(request)); },
  togoOrderManagementCreate(request) { return handleRequest('togo/order/mmtId', method.POST, toJsonStr(request)); },
  togoOmOnload(OMId, guestId) { return handleRequest(`togo/order/om/${OMId}/${guestId}`, method.GET, null); },
  togoCartCreate(request) { return handleRequest('togo/cart/create', method.POST, toJsonStr(request)); },
  togoOrderClosed(request) { return handleRequest('togo/close/order', method.POST, toJsonStr(request)); },
  togoOrderRelease(request) { return handleRequest('togo/release/order', method.POST, toJsonStr(request)); },
  getSelectCustomerDetails(guestId) { return handleRequest(`select/guest/phone/${guestId}`, method.GET, null); },
  getGuestIdDetails(guestId) { return handleRequest(`select/guest/${guestId}`, method.GET, null); },
  guestDeliveryAddressUpdated(request) { return handleRequest('togo/guest/addr/id/update', method.PUT, toJsonStr(request)); },
  // getCustViewDetails(custId) {
  // return handleRequest("customer/view/" + custId, method.GET, null) },
  onCustomerUpdate(request) { return handleRequest('guest/update', method.PUT, toJsonStr(request)); },
  pickUpDeliveryNow(request) { return handleRequest('togo/pickup/now', method.POST, toJsonStr(request)); },
  pickUpLater(request) { return handleRequest('togo/pickup/later', method.POST, toJsonStr(request)); },
  deliveryLater(request) { return handleRequest('togo/deliver/later', method.POST, toJsonStr(request)); },
  guestAddressUpdate(request) { return handleRequest('guest/addr/update', method.PUT, toJsonStr(request)); },
  getOnlineTogoOrdersList() { return handleRequest('getOnlineTogoOrdersList', method.GET, null); },
  rePrintSTKOnlineOrder(request) { return handleRequest('oos/STK/reprint', method.PUT, toJsonStr(request)); },

  // Payment
  paymentUpdate(request) { return handleRequest('payment/create', method.POST, toJsonStr(request)); },
  getPaymentDetails(overViewIds) { return handleRequest('payment/list', method.POST, toJsonStr(overViewIds)); },
  cancelPayment(request) { return handleRequest('cancel/payment', method.POST, toJsonStr(request)); },
  refundPayment(request) { return handleRequest('refund/payment', method.POST, toJsonStr(request)); },
  refundPaymentByCash(request) { return handleRequest('refund/payment/cash', method.POST, toJsonStr(request)); },
  refundPaymentByReward(request) { return handleRequest('refund/payment/reward', method.POST, toJsonStr(request)); },
  voidPayment(request) { return handleRequest('void/payment', method.POST, toJsonStr(request)); },

  // Rewards
  rewardsUpdate(request) { return handleRequest('update/rewards', method.PUT, toJsonStr(request)); },

  // CR Module
  getCashRegisterMasterList() { return handleRequest('cashregister/list', method.GET, null); },
  getActiveCashRegister() { return handleRequest('cashregister/getActiveCashRegister', method.GET, null); },
  createCashRegisterMaster(request) { return handleRequest('cashregister/create', method.POST, toJsonStr(request)); },
  updateCashRegisterMaster(request) { return handleRequest('cashregister/update', method.PUT, toJsonStr(request)); },
  deleteCashRegisterMaster(cashregisterId) { return handleRequest(`cashregister/delete/${cashregisterId}`, method.DELETE, null); },
  getClosedDetailsList(cashRegisterId, crMain) { return handleRequest(`close/cr/select/${cashRegisterId}/${crMain}`, method.GET, null); },
  getOpenDetailsList(cashRegisterId) { return handleRequest(`open/newCr/${cashRegisterId}`, method.GET, null); },
  createNewCr(cashRegisterId, request) { return handleRequest(`open/newCr/${cashRegisterId}`, method.POST, toJsonStr(request)); },
  getDetailsOfPreviousClose(cashRegisterId) { return handleRequest(`cr/checkForPreviousClose/${cashRegisterId}`, method.GET, null); },
  getCrDashboardList(cashRegisterId) { return handleRequest(`cr/onLoad/${cashRegisterId}`, method.GET, null); },
  createCashRegister(cashRegisterId, request) { return handleRequest(`cr/insert/${cashRegisterId}`, method.POST, toJsonStr(request)); },
  closeCashRegister(crMainId, request) { return handleRequest(`cr/close/${crMainId}`, method.POST, toJsonStr(request)); },
  createDropAddCash(cashRegisterId, crMain, request) { return handleRequest(`cr/drop/Add/insert/${cashRegisterId}/${crMain}`, method.POST, toJsonStr(request)); },
  createRequestChange(cashRegisterId, crMain, request) { return handleRequest(`request/change/${cashRegisterId}/${crMain}`, method.POST, toJsonStr(request)); },
  getVendorList() { return handleRequest('vendor/active', method.GET, null); },
  getEmployeeList() { return handleRequest('employee/active', method.GET, null); },
  getFloorList() { return handleRequest('floor/list', method.GET, null); },
  getPrintDetails() { return handleRequest('print/details', method.GET, null); },
  createPayout(request) { return handleRequest('payout/create', method.POST, toJsonStr(request)); },

  // REPORT
  getDailySalesReport(startDate, endDate) { return handleRequest(`report/select/${startDate}/${endDate}`, method.GET, null); },
  printDailySalesReport(startDate, endDate) { return handleRequest(`report/dailySales/print/${startDate}/${endDate}`, method.GET, null); },
  getFeedBackReport(startDate, endDate) { return handleRequest(`feedback/select/${startDate}/${endDate}`, method.GET, null); },

  // PRINT
  printReceiptCustomer(request) { return handleRequest('customer/print/receipt', method.POST, toJsonStr(request)); },
  togoPrintReceiptCustomer(request) { return handleRequest('customer/print/receipt/togo', method.POST, toJsonStr(request)); },
  printUpdate(request) { return handleRequest('cancel/to/kitchen', method.PUT, toJsonStr(request)); },

  // WAITLIST
  getWaitList() { return handleRequest('waitinglist/list', method.GET, null); },
  createWaitListForm(request) { return handleRequest('waitinglist/create', method.POST, toJsonStr(request)); },
  assignTableStatusUpdate(request) { return handleRequest('waitinglist/status/update', method.PUT, toJsonStr(request)); },
  // RESERVATION
  reservationForm(request) { return handleRequest('reservations/create', method.POST, toJsonStr(request)); },
  guestModeReservationForm(request) { return handleRequest('guestMode/reservations/create', method.POST, toJsonStr(request)); },
  getReservationList() { return handleRequest('reservations/list', method.GET, null); },
  getGuestModeReservationList() { return handleRequest('guestMode/reservations/list', method.GET, null); },
  updateReservationItem(request) { return handleRequest('reservations/update', method.PUT, toJsonStr(request)); },
  guestModeUpdateReservationItem(request) { return handleRequest('guestMode/reservations/update', method.PUT, toJsonStr(request)); },
  deleteReservationItem(request) { return handleRequest('reservations/delete', method.DELETE, toJsonStr(request)); },
  guestModeDeleteReservationItem(request) { return handleRequest('guestMode/reservations/delete', method.DELETE, toJsonStr(request)); },
  // NOTIFICATION
  getNotifications(request) { return handleRequest(`notifications/get/${request.category}/${request.offset}/${request.limit}`, method.GET, null); },

  // Feedback
  createfbQuestion(request) { return handleRequest('feedback/Question/create', method.POST, toJsonStr(request)); },
  getfbQuestions() { return handleRequest('feedback/Question/select', method.GET, null); },
  getGuestModefbQuestions() { return handleRequest('feedback/Question/select', method.GET, null); },
  updatefbQuestions(request) { return handleRequest('feedback/Question/update', method.PUT, toJsonStr(request)); },
  postGuestFeedback(request) { return handleRequest('feedback/create', method.POST, toJsonStr(request)); },
  postGuestModeGuestFeedback(request) { return handleRequest('guestMode/feedback/create', method.POST, toJsonStr(request)); },
  // Inventory OM
  stkInventory(request) { return handleRequest('inventory/mmt', method.PUT, toJsonStr(request)); },
  cancelToKitchenInventory(request) { return handleRequest('ctk/inventory/mmt ', method.PUT, toJsonStr(request)); },

  // DigitalSign
  updateDigiSign(request) { return handleRequest('signature/update', method.PUT, toJsonStr(request)); },

  ProductUserLogin(request) { return handleRequest('user/login', method.POST, toJsonStr(request)); },
  LocationLogin(request) { return handleRequest('user/location/login', method.POST, toJsonStr(request)); },

  // Asset
  getCCAssetDetails() { return handleRequest('asset/validate/mappedHsns', method.GET, null); },
};

export default UserServices;
