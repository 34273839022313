import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Kuber from './Kuber';

import './i18n';

import './static/scss/progressbar.scss';
import 'react-datetime/css/react-datetime.css';

// import "jquery/dist/jquery.min";
import './static/css/fonts.css';
import 'popper.js/dist/popper.min';
// import "bootstrap/dist/js/bootstrap.min";
// import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min'
// import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css'
//  import 'antd/dist/antd.css'
import './static/scss/app.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import './static/css/nucleo-icons.css';
import './static/demo/demo.css';
import './static/css/custom-styles.css';
import './static/scss/black-dashboard-pro-react.scss?v=1.1.0';
import './static/scss/custom.scss';

ReactDOM.render(
  <Suspense fallback={(<div>Loading</div>)}>
    {/* < Notifications type="success" message="Something happen" /> */}
    <Kuber />
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
