/* eslint-disable camelcase */
const LocalStorageHandler = {
  isGetUserAuthenticated: false,
  isProductRegistered: false,
  deviceDetails: false,
  userSetToken(token) {
    localStorage.setItem('userToken', token);
  },
  getUserToken() {
    return localStorage.getItem('userToken');
  },
  userLogout() {
    localStorage.removeItem('userToken');
  },
  getUserAuthenticated() {
    this.isGetUserAuthenticated = !!localStorage.getItem('userToken');
    return this.isGetUserAuthenticated;
  },

  setAssetId(assetId) {
    localStorage.setItem('assetId', assetId);
  },
  getAssetId() {
    localStorage.getItem('assetId');
  },
  setSortOrderRecord(record) {
    localStorage.setItem('sortOrder', record);
  },
  getSortOrderRecord() {
    return localStorage.getItem('sortOrder');
  },
  removeSortOrderRecord() {
    localStorage.removeItem('sortOrder');
  },

  clearCache: (callback) => {
    LocalStorageHandler.removeSortOrderRecord();
    LocalStorageHandler.userLogout();
    callback();
  },

  // Languages storage
  setLanguage: (value = 'en', label = 'English') => {
    localStorage.setItem('languageValue', value);
    localStorage.setItem('languageLabel', label);
  },
  getLanguage() {
    return {
      value: localStorage.getItem('languageValue')
        ? localStorage.getItem('languageValue')
        : 'en',
      label: localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'English',
    };
  },
  setProductToken(productToken) {
    localStorage.setItem('productToken', productToken);
  },
  getProductToken() {
    return localStorage.getItem('productToken');
  },
  getProductAuthenticated() {
    this.isProductRegistered = !!localStorage.getItem('productToken');
    return this.isProductRegistered;
  },
  getDeviceDetails() {
    const dd = JSON.parse(localStorage.getItem('deviceDetails'));
    return dd;
  },
  setDeviceDetails(details) {
    return localStorage.setItem('deviceDetails', JSON.stringify(details || {}));
  },
  clearSortOrder() {
    LocalStorageHandler.removeSortOrderRecord();
  },
  logout() {
    localStorage.removeItem('userToken');
    localStorage.removeItem('productToken');
    localStorage.removeItem('assetId');
    LocalStorageHandler.removeSortOrderRecord();
    LocalStorageHandler.userLogout();
  },
};

export default LocalStorageHandler;
