/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Row, Col, Button, ButtonGroup,
} from 'reactstrap';

const TabDetails = ({ currentActive, onChangeTabHandler }) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div>
      <Row className="my-2">

        <Col md={location.pathname === '/admin/location/config/list' ? '12' : '11'}>
          <ButtonGroup className="w-100">
            <Button
              color="info"
              style={{ minWidth: '100px' }}
              onClick={() => onChangeTabHandler(1)}
              className={currentActive === 1 ? 'info btn-round ' : 'btn btn-secondary btn-round'}
            >
              <span>
                {`${t('genericTerms.basic')}`}
              </span>

            </Button>
            <Button
              color="info"
              onClick={() => onChangeTabHandler(2)}
              className={currentActive === 2 ? 'info btn-round ' : 'btn btn-secondary btn-round'}
            >
              <span>
                {`${t('genericTerms.tax')}es`}
              </span>

            </Button>

            {/* <Button
              color="info"
              onClick={() => onChangeTabHandler(3)}
              className={currentActive === 3 ? 'info btn-round ' : 'btn btn-secondary btn-round'}
            >
              <span>
                {`${t('genericTerms.time')}`}
              </span>

            </Button> */}
            <Button
              color="info"
              onClick={() => onChangeTabHandler(4)}
              className={currentActive === 4 ? 'info btn-round ' : 'btn btn-secondary btn-round'}
            >
              <span>
                {`${t('genericTerms.contact')}`}
              </span>

            </Button>
            <Button
              color="info"
              onClick={() => onChangeTabHandler(5)}
              className={currentActive === 5 ? 'info btn-round ' : 'btn btn-secondary btn-round'}
            >
              <span>
                {`${t('genericTerms.other')}`}
              </span>

            </Button>

            <Button
              color="info"
              onClick={() => onChangeTabHandler(6)}
              className={currentActive === 6 ? 'info btn-round ' : 'btn btn-secondary btn-round'}
            >
              <span>
                {`${t('locationConfig.voidReason')} `}
              </span>

            </Button>
            <Button
              color="info"
              style={{ minWidth: '5px' }}
              onClick={() => onChangeTabHandler(7)}
              className={currentActive === 7 ? 'info btn-round ' : 'btn btn-secondary btn-round'}
            >
              <span>
                {`${t('genericTerms.time')} ${t('genericTerms.details')} `}
              </span>

            </Button>
            {/* <Button
              color="info"
              onClick={() => onChangeTabHandler(8)}
              className={currentActive === 8 ? 'info btn-round ' : 'btn btn-secondary btn-round'}
            >
              <span>
                {`${t('menu.categoryTimeTable')} `}
              </span>
            </Button> */}
            <Button
              color="info"
              onClick={() => onChangeTabHandler(8)}
              className={currentActive === 8 ? 'info btn-round ' : 'btn btn-secondary btn-round'}
            >
              <span>
                {`${t('locationConfig.jobRole')} `}
              </span>
            </Button>

          </ButtonGroup>

        </Col>
      </Row>
    </div>
  );
};

export default TabDetails;
