import React from 'react';

import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';

const ActionButtons = ({ title, onFormSubmitted, onNavigate }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className=" justify-content-end">
        <Col lg={12} className="text-right">
          <Button
            type="button"
            onClick={(el) => onNavigate(el)}
            className="btn-black-color btn-black-color1"
          >
            {t('genericTerms.cancel')}
          </Button>
          <Button
            type="button"
            className="btn-primary-color btn-primary-color1"
            onClick={(el) => onFormSubmitted(el)}
            color="info"
          >
            {title}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ActionButtons;
