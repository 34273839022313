/* eslint-disable no-empty */
const { default: AdminServices } = require('../../../services/AdminServices');

const ServiceHandler = {
  async getVirtualPrinterList(props) {
    const resp = await AdminServices.getVirtualPrinterList();
    const result = await resp.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },

  async getWeekDays(props) {
    try {
      const resp = await AdminServices.getOfferViewDetails(undefined);
      const result = await resp.json();
      AdminServices.authUser(result.errors, props);
      return result;
    } catch {

    }
  },
  async getCategoryDetails(categoryId, props) {
    const resp = await AdminServices.getCategoryViewDetails(categoryId);
    const result = await resp.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },

  async createCategory(request, props) {
    const response = await AdminServices.createCategory(request);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async updateCategory(request, props) {
    const response = await AdminServices.updateCategory(request);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async getCategoryList(props) {
    const response = await AdminServices.getCategoryList();
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async categoryImgUpload(formData, categoryId, props) {
    const response = await AdminServices.categoryImageUpload(categoryId, formData);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
  async deleteCategoryImg(categoryId, props) {
    const response = await AdminServices.deleteCategoryImg(categoryId);
    const result = await response.json();
    AdminServices.authUser(result.errors, props);
    return result;
  },
};

export default ServiceHandler;
