import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import log from './components/common/logger';
import KuberLayout from './components/layouts/KuberLayout';
import Splash from './components/Splash';
import { Provider } from './components/common/appUseContext';
import ScrollToTop from './components/common/scroll-to-top/ScrollToTop';

export const UseContext = React.createContext({});

const Kuber = () => {
  log.info('*** Kuber ***');
  const [loading, setLoading] = useState(true);

  function appReady() {
    setTimeout(() => setLoading(false), 1000);
  }
  return (

    <Provider>
      {loading
        ? <Splash onReady={appReady} />
        : (
          <BrowserRouter>
            <ScrollToTop>
              <KuberLayout />
            </ScrollToTop>
          </BrowserRouter>
        )}
    </Provider>

  );
};

export default Kuber;
