// TODO  if we need first login we can use this code

// import React, { lazy, Suspense } from 'react';
// import { Redirect, Route, Switch, withRouter } from "react-router-dom";
// import log from "../common/logger";
// import LocationAddUpdateForm from "../modules/admin/location-config/LocationAddUpdateForm";
// import LocalStorageHandler from "../modules/auth/LocalStorageHandler";
// import PrivateRoute from "./PrivateRoute";

// const AppLayout = lazy(() => import('../layouts/AppLayout'));
// const CustomerLayout = lazy(() => import('../layouts/CustomerLayout'));
// const ServiceLogin = lazy(() => import('../modules/auth/ServiceLogin'));
// const NotFound = lazy(() => import('../modules/not-found/NotFound'));
// const KuberServiceLogin = lazy(() => import('../modules/auth/KuberServiceLogin'));

// const KuberRoute = (props) => {
//     log.info('**** KuberRoute ****');
//     if (LocalStorageHandler.getKuberAuthenticated() && LocalStorageHandler.getUserAuthenticated() &&
//         props.location.pathname === '/kuber') {
//         return <Redirect to="/" />
//     } else if (LocalStorageHandler.getKuberAuthenticated() && props.location.pathname === '/kuber/login' && !LocalStorageHandler.getUserAuthenticated()) {
//         return <Redirect to="/login" />
//     } else if (!LocalStorageHandler.getKuberAuthenticated() && !LocalStorageHandler.getUserAuthenticated() &&
//         props.location.pathname !== '/kuber/login') {
//         return <Redirect to="/kuber/login" />
//     }

//     return (
//         <Suspense fallback={<div />}>
//             <Switch>
//                 <Redirect exact from="/customer" to="/customer/join" />

//                 <Route path='/customer' component={CustomerLayout} />
//                 <Route path='/login' component={ServiceLogin} />
//                 <Route path='/location/config/create' component={LocationAddUpdateForm} />
//                 <Route path='/kuber/login' component={KuberServiceLogin} />
//                 <PrivateRoute path='/' component={AppLayout} />
//                 <Route to='*' component={NotFound} />
//             </Switch>
//         </Suspense>
//     );
// }
// export default withRouter(KuberRoute);

import React, { lazy, Suspense } from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { Provider } from '../common/appUseContext';
import log from '../common/logger';
import LocationAddUpdateForm from '../modules/admin/location-config/layout/LocationAddUpdateForm';
import LocalStorageHandler from '../modules/auth/LocalStorageHandler';
import PrivateRoute from './PrivateRoute';

const AppLayout = lazy(() => import('../layouts/AppLayout'));
const CustomerLayout = lazy(() => import('../layouts/CustomerLayout'));
const ServiceLogin = lazy(() => import('../modules/auth/ServiceLogin'));
const NotFound = lazy(() => import('../modules/not-found/NotFound'));
const MyRewardsLayout = lazy(() => import('../modules/customer/my-rewards/MyRewardsLayout'));
const ProductLogin = lazy(() => import('../modules/auth/ProductLogin'));
const LocationLogin = lazy(() => import('../modules/auth/LocationsLogin'));
const DemoTablePage = lazy(() => import('../common/StickyTable/DemoTablePage'));

const KuberRoute = (props) => {
  log.info('**** KuberRoute ****');
  if (LocalStorageHandler.getUserAuthenticated()
    && props.location.pathname === '/kuber') {
    return <Redirect to="/" />;
  } if (props.location.pathname === '/kuber/login' && !LocalStorageHandler.getUserAuthenticated()) {
    return <Redirect to="/login" />;
  }

  return (
    <Provider>
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path="/sticky-table" component={DemoTablePage} />

          <Route path="/login" component={ServiceLogin} />
          <Redirect exact from="/customer" to="/customer/join" />
          <Route path="/productLogin" component={ProductLogin} />
          <Route path="/locations" component={LocationLogin} />
          <Route path="/customer-rewards" component={MyRewardsLayout} />
          <Route path="/customer" component={CustomerLayout} />
          <Route path="/login/:productToken" component={ServiceLogin} />
          <Route path="/location/config/create" component={LocationAddUpdateForm} />
          <PrivateRoute path="/" component={AppLayout} />
          <Route to="*" component={NotFound} />

        </Switch>
      </Suspense>
    </Provider>

  );
};

export default withRouter(KuberRoute);
