/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import sign from 'jwt-encode';
import {
  Button as ButtonNew,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import logger from './logger';
import UserServices from '../modules/services/UserServices';
import Loader from '../modules/Alert/Loader';
import Toast from './Toast';

const PasscodeLayout = (props) => {
  const { t } = useTranslation();

  const [passcodeData, setPassCodeData] = useState('');
  const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0, ''];
  const [validatePasscode, setValidatePasscode] = useState('');
  const [toastData, setToastData] = useState('');
  const [circleButton, setCircleButton] = useState([
    { id: 1, active: false },
    { id: 2, active: false },
    { id: 3, active: false },
    { id: 4, active: false },
    { id: 5, active: false },
  ]);
  const [circleValue, setCircleValue] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  const onCircleFillColor = () => {
    if (circleValue.length > 0) {
      if (circleValue.length === 1) circleButton[0].active = true;
      if (circleValue.length === 2) circleButton[1].active = true;
      if (circleValue.length === 3) circleButton[2].active = true;
      if (circleValue.length === 4) circleButton[3].active = true;
      if (circleValue.length === 5) circleButton[4].active = true;
    }
  };

  const onDeletePassCode = () => {
    const circleButtonList = [...circleButton];
    const circleValueList = [...circleValue];

    if (circleValueList.length === 1) {
      circleValueList.splice(0, 1);
      circleButtonList[0].active = false;
    }
    if (circleValueList.length === 2) {
      circleValueList.splice(1, 1);
      circleButtonList[1].active = false;
    }
    if (circleValueList.length === 3) {
      circleValueList.splice(2, 1);
      circleButtonList[2].active = false;
    }
    if (circleValueList.length === 4) {
      circleValueList.splice(3, 1);
      circleButtonList[3].active = false;
    }
    if (circleValueList.length === 5 || circleValueList.length === 6) {
      circleValueList.splice(4, 1);
      circleButtonList[4].active = false;
    }
    if (circleValueList.length === 0) setPassCodeData('');
    passcodeData.slice(0, -1);
    setCircleValue(circleValueList);
    setCircleButton(circleButtonList);
  };

  const buttonClick = (e, value) => {
    setValidatePasscode('');
    const code = passcodeData.concat(value);
    setPassCodeData(passcodeData.concat(value));
    circleValue.push(value);
    setCircleValue(circleValue);
    // setPassCode(passcodeData);
    onApiCall(code);
    onCircleFillColor(e);
  };

  const onApiCall = (code) => {
    if (code.length === 5) processLogin(code);
  };

  const loaderShowHide = (isBoolean) => setIsLoader(isBoolean);
  const processLoginFrom = () => processLogin();
  const processLogin = async (code) => {
    const encodedAdminPasscode = sign(code, process.env.REACT_APP_PASSCODE);
    const REQ = { passCode: encodedAdminPasscode };
    loaderShowHide(true);
    const response = await UserServices.adminManagerVerification(REQ.passCode);
    const result = await response.json();
    loaderShowHide(false);
    setToastData(result);
    if (result.status === 200) {
      onApproveFormHide();
      props.handleSubmit();
    } else if (result.status === 400) {
      setPassCodeData('');
      circleButton.map((button) => button.active = false);
      // setPassCode('');
      setCircleValue([]);
    }
  };

  const resetData = () => {
    setValidatePasscode('');
    setPassCodeData('');
    circleButton.map((button) => button.active = false);
    setCircleValue([]);
  };

  const onToggleOpenHide = () => {
    resetData();
    props.onShowApproveForm();
  };

  useEffect(() => {
    logger.info('*** PasscodeLayout ***');
  }, []);

  const onApproveFormHide = () => {
    resetData();
    props.onShowApproveForm();
  };

  return (
    <>
      {isLoader === true
        && <Loader onCancel={() => setIsLoader(false)} />}
      <Toast toastData={toastData} />
      <Modal size="sm" isOpen={props.approveModal} toggle={() => onToggleOpenHide} centered className="dineInFloorModal">
        <ModalHeader toggle={() => onToggleOpenHide()} className="passcodeLayoutModal">
          <div className="modal-title">
            {' '}
            {t('genericTerms.adminVerificationCodeTitle')}
          </div>
        </ModalHeader>
        <ModalBody className="passcodeLayoutModal">
          <Row className="justify-content-center">
            <Col md={12} lg={12} sm={12}>
              <form onSubmit={processLoginFrom.bind(this)}>
                <Row className="justify-content-center">
                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <Col>
                        <p className="text-center enterPasscode"> Enter Passcode</p>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-center">
                      <Row>
                        <Col className="text-center passcodeBtnsSec sa-password-text">
                          {circleButton.map((item, i) => <ButtonNew key={i} disabled className="btn-round btn-icon" color={item.active === true ? 'success' : 'default'} size="sm" />)}
                        </Col>
                      </Row>
                    </div>
                    <div className="small text-danger">
                      {validatePasscode}
                      {' '}
                    </div>
                  </Col>
                </Row>
              </form>

              <Row className="justify-content-center">

                <Col md="12">
                  <div className="num-pad">
                    {buttons.map((item, i) => (
                      <div className="span4" key={i}>
                        {item !== ''
                          && (
                            <div className="num" onClick={(e) => buttonClick(e, item)}>
                              <div className="txt disable-select">
                                {item}
                              </div>
                            </div>
                          )}
                      </div>
                    ))}
                    {circleValue.length > 0
                      && (
                        <div className="span4">
                          <div className="num" onClick={(el) => onDeletePassCode(el)}>
                            <div className="txt disable-select">
                              <i className="fas fa-backspace" />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="clearfix" />

                </Col>

              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

    </>

  );
};

export default PasscodeLayout;
