/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  Row, Col, Label, Input, CustomInput,
} from 'reactstrap';

const EditBasicDetails = ({
  locationDetails, currencyTypeList, handleFormChange, selectCurrencyType, chooseLogo, imgPreviewUrl, onLogoImgCancel,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <h4>{`${t('genericTerms.basic')} ${t('genericTerms.details')}`}</h4>
        </Col>
      </Row>
      <Row>
        <Col lg={3} md={4} sm={6}>
          <Label htmlFor="businessName" className="required text-muted fs-12px">
            {`${t('locationConfig.business')} ${t('genericTerms.name')}`}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            type="text"
            className="form-control"
            placeholder="Business Name"
            id="businessName"
            defaultValue={locationDetails?.businessName}
            name="businessName"
          />
        </Col>
        <Col lg={3} md={4} sm={6}>
          <Label htmlFor="locationType" className="required text-muted fs-12px">
            {`${t('genericTerms.location')} ${t('genericTerms.type')}`}
          </Label>
          <Input
            onChange={(event) => handleFormChange(event)}
            type="text"
            className="form-control form-control1"
            placeholder="Location Type"
            id="locationType"
            defaultValue={locationDetails?.locationType}
            name="locationType"
          />
        </Col>

        {/* <Col lg={3} md={4} sm={6}>
          <Label htmlFor="locationVersion" className="required text-muted fs-12px">
            {`${t('genericTerms.location')} ${t('locationConfig.version')}`}
          </Label>
          <Input
            //  ref={(el) => (this.locationVersion = el)}
            type="text"
            onChange={(event) => handleFormChange(event)}
            className="form-control form-control1"
            placeholder="Location Version"
            id="locationVersion"
            defaultValue={locationDetails?.locationVersion}
            name="locationVersion"
          />
        </Col> */}
        <Col lg={3} md={4} sm={6}>
          <Label className="required text-muted fs-12px">{`${t('genericTerms.location')} ${t('genericTerms.currency')} ${t('genericTerms.type')}`}</Label>
          <select
            style={{
              color: 'black',
              backgroundColor: '#fff',
            }}
            className="custom-select"
            value={locationDetails?.locationCurrencyType}
            onChange={(event) => selectCurrencyType(event)}
          >
            {currencyTypeList.map((item, i) => (
              <option key={i} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>
          <Label htmlFor="email" className="required text-muted fs-12px">
            {t('genericTerms.email')}
          </Label>
          <Input
            //  ref={(el) => (this.emailId = el)}
            onChange={(event) => handleFormChange(event)}
            type="text"
            className="form-control form-control1"
            placeholder="Email"
            id="email"
            defaultValue={locationDetails?.emailId}
            name="emailId"
          />
        </Col>

        <Col lg={3} md={4} sm={6}>
          <Label htmlFor="geoCode" className="required text-muted fs-12px">
            {t('genericTerms.geoCode')}
          </Label>
          <Input
            onChange={handleFormChange}
            //  ref={(el) => (this.geoCode = el)}
            type="text"
            className="form-control form-control1"
            placeholder="country code"
            id="geoCode"
            maxLength={5}
            size="10"
            defaultValue={locationDetails?.geoCode}
            name="geoCode"
          />
        </Col>

        <Col lg={3} md={4} sm={6}>
          <Label htmlFor="contactNumber" className="required text-muted fs-12px">
            {t('genericTerms.contactNumber')}
          </Label>
          <Input
            type="number"
            onChange={(event) => handleFormChange(event)}
            className="form-control form-control1"
            placeholder="Contact Number"
            id="contactNumber"
            maxLength={10}
            size="10"
            defaultValue={locationDetails?.contactNumber}
            name="contactNumber"
          />
        </Col>
      </Row>

      <Row className="my-2">
        <Col lg={6} md={12} sm={12}>
          <h4>{t('genericTerms.chooseLogo')}</h4>
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={3} md={4} sm={6}>
          <Label htmlFor="upload" className="required text-muted fs-12px">
            {t('genericTerms.chooseLogo')}
            <span className="mx-3">
              <i className="cursor-pointer fas fa-paperclip" />
            </span>
          </Label>

          <Input id="upload" className="d-none" type="file" onChange={(el) => chooseLogo(el)} name="Choose File" accept="image/*" />
        </Col>
      </Row>
      {imgPreviewUrl !== null && (
        <Row className=" my-2">
          <Col lg={2} md={8}>
            <div className="lauUploadImage">
              <img src={imgPreviewUrl} style={{ height: '100%', width: '100%' }} alt="" />
              <i onClick={() => onLogoImgCancel()} style={{ top: '0px', right: '-20px' }} className="fas fa-times-circle position-absolute cursor-pointer" />
            </div>
          </Col>
        </Row>
      )}

      <Row className="my-2">
        <Col lg={4} md={6} sm={12}>
          <CustomInput
            onChange={(event) => handleFormChange(event)}
            type="checkbox"
            checked={locationDetails?.locationActive}
            // ref={(el) => (this.locationActive = el)}
            id="isActive"
            name="locationActive"
            label={t('genericTerms.active')}
            inline
          />
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default EditBasicDetails;
